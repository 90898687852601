import * as React from "react";
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import "./LoginPhoneModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoginPhoneModal,
  loginUserOnPhone,
  logoutUserFromPhone,
  showLoginPhoneModal,
} from "src/store/actions/auth";
import { IRootState } from "src/store/reducers";
import { isElectron, usePrevious } from "src/utils";
import { handleError } from "src/utils/errorHandler";
import Button from "../UI/Button/Button";
import SearchInput from "../UI/SearchInput/SearchInput";
import { Loader } from "../UI/Loader/Loader";
import Modal from "../UI/Modal/Modal";
import { BridgeColor } from "src/utils/consts";

import { User } from "compass.js";
import { createSelector } from "reselect";
import Table from "src/components/UI/Table/Table";
import { IPhoneData } from "src/store/reducers/auth";
import { DialerWebrtcContext } from "src/providers/DialerWebrtcContext";
import {
  fetchSipAccount,
  fetchCompanyPhones,
  fetchUserBridgePhones,
} from "src/utils/phone";
import { PermissionStatus } from "src/providers/DialerWebrtcContext";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
export enum LoginPhoneModalType {
  switch = "switch",
  login = "login",
}

enum LoginPhoneModalVisibleContent {
  start = "start",
  phoneSelect = "phoneSelect",
}

interface ILoginPhoneModalState {
  visibleContent: LoginPhoneModalVisibleContent | null;
  searchQuery: string;
  companyPhones: Array<{
    id: number;
    name: string;
    type: string;
    userId?: number;
    userName?: string;
    isBridgePhone?: boolean;
  }>;
  selectedPhone?: {
    id: number;
    name: string;
    online: boolean;
    userId?: number;
    userName?: string;
    isBridgePhone?: boolean;
  };
  isSubmitting: boolean;
  isLoading: boolean;
}
const selectAuth = (state: IRootState) => state.auth;
const selectContacts = (state: IRootState) => state.contacts;

const appDataSelector = createSelector(
  [selectAuth, selectContacts],
  (auth, contacts) => ({
    phone: auth.phone,
    connection: auth.connection,
    company: auth.company,
    userId: auth.user && auth.user.id,
    type: auth.loginPhoneModalType,
    showLoginPhoneModalState: auth.showLoginPhoneModal,
    compassContacts: contacts.compassItems,
    recentPhones: auth.recentPhones,
    onboardingMode: auth.onboardingMode,
  })
);

const BridgePhoneSetup: React.FC<{
  onSuccess: () => void;
  onError: (error: any) => void;
}> = ({ onSuccess, onError }) => {
  const { setupDialer, permissionStatus } = useContext(DialerWebrtcContext);

  const { connection, userId, company } = useSelector(appDataSelector);

  const [isInitializing, setIsInitializing] = useState(false);

  useEffect(() => {
    const initializeBridgePhone = async () => {
      if (!userId || !connection || !company) {
        console.log("Bridge Phone: no userId, connection or company");
        return;
      }

      if (isInitializing) return; // Prevent multiple initialization attempts

      try {
        setIsInitializing(true);
        const bridgePhones = await fetchUserBridgePhones(
          Number(userId),
          connection
        );

        if (bridgePhones.length > 0) {
          const firstBridgePhone = bridgePhones[0];
          const bridgePhoneId = firstBridgePhone.resourceId;
          const { fetchSipAccount } = await import("src/utils/phone");
          await fetchSipAccount(bridgePhoneId, connection, setupDialer);
          onSuccess();
        }
      } catch (error) {
        onError(error);
      } finally {
        setIsInitializing(false);
      }
    };

    if (permissionStatus === PermissionStatus.granted) {
      initializeBridgePhone();
    }
  }, [
    permissionStatus,
    connection,
    userId,
    company,
    setupDialer,
    onSuccess,
    onError,
    isInitializing,
  ]);

  return null;
};

const LoginPhoneModal: React.FC = () => {
  const {
    phone,
    connection,
    company,
    userId,
    type,
    showLoginPhoneModalState,
    compassContacts,
    recentPhones,
    onboardingMode,
  } = useSelector(appDataSelector);
  const dispatch = useDispatch();

  const hasMounted = useRef(false);

  const {
    hasBridgePhones,
    hasCallingWithBridgeFeature,
    dialer,
    setupDialer,
    permissionStatus,
  } = useContext(DialerWebrtcContext);

  const [visibleContent, setVisibleContent] =
    useState<LoginPhoneModalVisibleContent | null>(null);
  const [companyPhones, setCompanyPhones] = useState<
    ILoginPhoneModalState["companyPhones"]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPhone, setSelectedPhone] =
    useState<ILoginPhoneModalState["selectedPhone"]>();
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showBridgePhoneSetup, setShowBridgePhoneSetup] = useState(false);
  const [sort, setSort] = useState<
    { column: number; sort: "asc" | "desc" } | undefined
  >();

  const prevType = usePrevious(type);
  const prevPhone = usePrevious(phone);

  const onCloseLoginPhoneModal = useCallback(
    () => dispatch(closeLoginPhoneModal()),
    [dispatch]
  );

  const onLoginUserOnPhone = useCallback(
    (userId: User["id"], phoneId: number) =>
      dispatch<any>(loginUserOnPhone(userId, phoneId)),
    [dispatch]
  );

  const onLogoutUserFromPhone = (userId: User["id"]) => {
    return dispatch<any>(logoutUserFromPhone(userId));
  };

  const closeModal = useCallback(() => {
    onCloseLoginPhoneModal();
    setTimeout(() => {
      setSearchQuery("");
      setVisibleContent(null);
    }, 500);
  }, [onCloseLoginPhoneModal]);

  const getContent = () => {
    if (isLoading) {
      return (
        <div className="login-phone-modal__loading">
          <Loader color="primary" />
        </div>
      );
    }
    if (!visibleContent) {
      return null;
    }
    if (!companyPhones.length) {
      return getNoPhonesContent();
    }
    switch (visibleContent) {
      case LoginPhoneModalVisibleContent.start:
        return getStartContent();
      case LoginPhoneModalVisibleContent.phoneSelect:
        return getPhoneListContent();
    }
  };

  const loadPhones = useCallback(async () => {
    if (!connection || !company || onboardingMode) {
      return;
    }
    setIsLoading(true);

    try {
      const phones = await fetchCompanyPhones(
        connection,
        company.entityId,
        userId,
        compassContacts,
        recentPhones
      );
      setCompanyPhones(phones);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    connection,
    company,
    onboardingMode,
    userId,
    compassContacts,
    recentPhones,
  ]);

  const handleBridgePhoneLogin = useCallback(async () => {
    if (!userId || !connection || !company || !hasCallingWithBridgeFeature) {
      console.log("Bridge Phone: no userId, connection or company");
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const bridgePhones = companyPhones.filter((phone) => phone.isBridgePhone);

      if (bridgePhones.length > 0) {
        const firstBridgePhone = bridgePhones[0];
        const bridgePhoneId = firstBridgePhone.id;

        await onLoginUserOnPhone(userId, bridgePhoneId);
        closeModal();
        setShowBridgePhoneSetup(true);
        loadPhones();

        setCompanyPhones((prevPhones) => {
          return prevPhones.map((phone) => {
            if (phone.id === bridgePhoneId) {
              return {
                ...phone,
                userId: Number(userId),
                userName: "Your current phone",
              };
            }
            return phone;
          });
        });
      }
    } catch (error) {
      handleError(error);
      setShowBridgePhoneSetup(false);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    userId,
    connection,
    company,
    hasCallingWithBridgeFeature,
    isSubmitting,
    companyPhones,
    onLoginUserOnPhone,
    closeModal,
    loadPhones,
  ]);

  const handlePermissionModalClose = () => {
    setShowPermissionModal(false);
  };

  const showPhoneContent = () => {
    setVisibleContent(LoginPhoneModalVisibleContent.phoneSelect);
    setSearchQuery("");
  };

  const showStartContent = () => {
    setVisibleContent(LoginPhoneModalVisibleContent.start);
    setSearchQuery("");
  };

  const onSearchInputChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const onLoginUserOnPhoneHandler = async () => {
    const selectedPhoneId = selectedPhone && selectedPhone.id;
    if (!userId || !selectedPhoneId) {
      return;
    }
    setIsSubmitting(true);

    try {
      await onLoginUserOnPhone(userId, selectedPhoneId);
      closeModal();
      loadPhones();
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onLogoutOfPhone = async () => {
    const userPhone = phone;

    if (!userPhone || !userId) {
      return;
    }
    setIsSubmitting(true);

    try {
      const wasLoggedToPhoneId = userPhone.id;
      await onLogoutUserFromPhone(userId);
      const updatedCompanyPhones = companyPhones.slice();
      for (const companyPhone of updatedCompanyPhones) {
        if (companyPhone.id === wasLoggedToPhoneId) {
          companyPhone.userId = undefined;
          companyPhone.userName = undefined;
          break;
        }
      }
      setCompanyPhones(updatedCompanyPhones);
      dispatch(showLoginPhoneModal(LoginPhoneModalType.login));
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCurrentUserDisplay = (
    companyPhone: ILoginPhoneModalState["companyPhones"][0],
    currentPhone: IPhoneData | null | undefined
  ): string => {
    const isCurrentPhone = currentPhone && currentPhone.id === companyPhone.id;

    if (isCurrentPhone) {
      return "Your current phone";
    }

    if (companyPhone.isBridgePhone) {
      return "-";
    }

    if (companyPhone.userName && companyPhone.userId) {
      return companyPhone.userName;
    }

    return "-";
  };

  const columns = useMemo(
    () => [
      { type: "radio" as const, extraCssClass: "w-[48px]" },
      { title: "Phone name", type: "string" as const, searchable: true },
      { title: "Phone type", type: "string" as const, searchable: true },
      { title: "Current user", type: "string" as const, searchable: true },
    ],
    []
  );
  const tableData = useMemo(
    () =>
      [...companyPhones]
        .sort((a, b) => {
          // Keep Bridge Phones at the top
          if (a.isBridgePhone && !b.isBridgePhone) return -1;
          if (!a.isBridgePhone && b.isBridgePhone) return 1;

          // Then sort by recent phones
          const aIsRecent = recentPhones.includes(a.id);
          const bIsRecent = recentPhones.includes(b.id);
          if (aIsRecent && !bIsRecent) return -1;
          if (!aIsRecent && bIsRecent) return 1;

          if (aIsRecent && bIsRecent) {
            return recentPhones.indexOf(a.id) - recentPhones.indexOf(b.id);
          }

          return a.type.localeCompare(b.type);
        })
        .map((companyPhone) => [
          { value: "", rawValue: companyPhone.id },
          {
            value: (
              <>
                {companyPhone.name}
                {(!phone || phone.id !== companyPhone.id) &&
                  recentPhones.includes(companyPhone.id) && (
                    <Tooltip content="Your recent phone">
                      <span className="login-phone-modal__select-phone-item-recent-icon">
                        <FontAwesomeIcon icon={faHistory} />
                      </span>
                    </Tooltip>
                  )}
              </>
            ),
            rawValue: companyPhone.name,
          },
          { value: companyPhone.type },
          { value: getCurrentUserDisplay(companyPhone, phone) },
        ]),
    [companyPhones, phone, recentPhones]
  );

  const onSelectPhone = useCallback(
    (index: number) => {
      if (isSubmitting) {
        return;
      }
      const phoneId = tableData[index][0].rawValue as number;
      const phoneIndex = companyPhones.findIndex(
        (phone) => phone.id === phoneId
      );

      setSelectedPhone({
        ...companyPhones[phoneIndex],
        online: true,
      });
    },
    [isSubmitting, companyPhones, tableData]
  );

  const handleColumnHeaderClick = useCallback(
    (column: number, sortDirection: "asc" | "desc" | "none") => {
      if (sortDirection === "none") {
        setSort(undefined);
      } else {
        setSort({ column, sort: sortDirection });
      }
      // Always keep bridge phones at the top
      setCompanyPhones((prevPhones) => [
        ...prevPhones.filter((phone) => phone.isBridgePhone),
        ...prevPhones.filter((phone) => !phone.isBridgePhone),
      ]);
    },
    []
  );

  const getPhoneListContent = () => {
    return (
      <div className="login-phone-modal__select-phone">
        <div className="login-phone-modal__select-phone-input">
          <SearchInput
            alwaysOpened={true}
            autoFocus={true}
            dark={true}
            expand="full"
            changed={onSearchInputChange}
            placeholder="Search phones..."
            className="search-input--modal"
          />
        </div>
        <div className="login-phone-modal__select-phone-items">
          <Table
            columns={columns}
            data={tableData}
            pagination={{ rowsPerPage: 100 }}
            onRowSelect={onSelectPhone}
            selectedValue={selectedPhone?.id} // Use phone ID instead of index
            searchQuery={searchQuery}
            sort={sort}
            onColumnHeaderClick={handleColumnHeaderClick}
          />
        </div>
        <div className="login-phone-modal__select-phone-buttons">
          {phone ? (
            <div className="login-phone-modal__select-phone-button-wrap">
              <Button
                color={BridgeColor.gs800}
                disabled={isSubmitting}
                onClick={onLogoutOfPhone}
              >
                Log out of my phone
              </Button>
            </div>
          ) : null}
          <div className="login-phone-modal__select-phone-button-wrap">
            <Button
              color={BridgeColor.prim500}
              disabled={
                !selectedPhone ||
                !!selectedPhone.userId ||
                !!(selectedPhone && phone && selectedPhone.id === phone.id) ||
                isSubmitting
              }
              onClick={onLoginUserOnPhoneHandler}
            >
              Select phone
            </Button>
          </div>
          <div className="login-phone-modal__select-phone-button-wrap">
            <Button
              color={BridgeColor.gs800}
              disabled={isSubmitting}
              onClick={
                type === LoginPhoneModalType.switch
                  ? closeModal
                  : showStartContent
              }
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getNoPhonesContent = () => {
    return (
      <div className="login-phone-modal__user-phone">
        {getHeaderTxt()}
        <div className="login-phone-modal__user-phone-info">
          You currently don’t have any registered phones in Compass. Contact
          administrator or sign in to Compass and add a phone.
        </div>
        <div className="login-phone-modal__user-phone-buttons">
          <div className="login-phone-modal__user-phone-button-wrap">
            <Button color={BridgeColor.prim500} onClick={loadPhones}>
              Try again
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getStartContent = () => {
    return (
      <div className="login-phone-modal__user-phone">
        {phone ? null : getHeaderTxt()}
        <div className="login-phone-modal__user-phone-info">
          Please make sure that you have call waiting enabled for your phone to
          be able to make attended transfers. You can enable it in Phone
          settings in Compass.
        </div>
        <div className="login-phone-modal__select-phone-buttons">
          {hasCallingWithBridgeFeature && hasBridgePhones ? (
            <>
              <div className="login-phone-modal__select-phone-button-wrap">
                <Button
                  color={BridgeColor.prim500}
                  onClick={handleBridgePhoneLogin}
                  disabled={isSubmitting}
                >
                  Use Bridge as a phone
                </Button>
              </div>
              <div className="login-phone-modal__select-phone-button-wrap">
                <Button
                  color={BridgeColor.gs800}
                  onClick={showPhoneContent}
                  disabled={isSubmitting}
                >
                  Use another phone
                </Button>
              </div>
            </>
          ) : (
            <>
              {phone ? (
                <>
                  <div className="login-phone-modal__select-phone-button-wrap">
                    <Button color={BridgeColor.prim500} onClick={closeModal}>
                      Continue with {phone.name}
                    </Button>
                  </div>
                  <div className="login-phone-modal__select-phone-button-wrap">
                    <Button
                      color={BridgeColor.gs200}
                      fill="clear"
                      onClick={showPhoneContent}
                    >
                      Switch phone
                    </Button>
                  </div>
                </>
              ) : (
                <div className="login-phone-modal__select-phone-button-wrap">
                  <Button
                    color={BridgeColor.prim500}
                    onClick={showPhoneContent}
                  >
                    Select a phone
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const getHeaderTxt = () => {
    return <div>You need to log in to a phone to be able to use Bridge</div>;
  };

  const getPermissionModalContent = () => {
    if (isElectron()) {
      return (
        <>
          <p>
            Bridge needs access to your microphone to enable calling features.
          </p>
          <p>You can grant access in System Preferences at any time.</p>
          <Button
            color={BridgeColor.prim500}
            onClick={() => window.electronAPI.openMediaAccessSettings()}
          >
            Open System Preferences
          </Button>
        </>
      );
    }

    return (
      "Grant your browser access to your microphone and speaker using the popup " +
      "on your screen to enable this feature. You can adjust these settings " +
      "anytime in your browser."
    );
  };

  useEffect(() => {
    if (permissionStatus === PermissionStatus.granted && showPermissionModal) {
      setShowPermissionModal(false);
    }
  }, [permissionStatus, showPermissionModal]);

  useEffect(() => {
    if (permissionStatus === PermissionStatus.pending && phone?.isBridgePhone) {
      setShowPermissionModal(true);
    }
  }, [permissionStatus, phone?.isBridgePhone]);

  // Update the initial load effect to handle modal visibility
  useEffect(() => {
    if (
      showLoginPhoneModalState &&
      (!hasMounted.current || companyPhones.length === 0)
    ) {
      loadPhones();
      hasMounted.current = true;
    }
  }, [loadPhones, showLoginPhoneModalState, companyPhones.length]);

  useEffect(() => {
    if (type && (!prevType || prevType !== type)) {
      setVisibleContent(
        type === LoginPhoneModalType.switch
          ? LoginPhoneModalVisibleContent.phoneSelect
          : LoginPhoneModalVisibleContent.start
      );
    }
  }, [prevType, type]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const phoneId = phone ? phone.id : null;
    const prevPhoneId = prevPhone ? prevPhone.id : null;
    if (phoneId !== prevPhoneId) {
      loadPhones();
    }
  }, [phone, isLoading, prevPhone, loadPhones]);

  useEffect(() => {
    const phoneId = phone ? phone.id : null;
    if (!connection || !phoneId) {
      return;
    }

    const initializeBridgePhone = async () => {
      if (phone?.isBridgePhone && dialer && !showBridgePhoneSetup) {
        try {
          await fetchSipAccount(phoneId, connection, setupDialer);
        } catch (error) {
          handleError(error);
        }
      }
    };

    initializeBridgePhone();
  }, [phone, setupDialer, connection, dialer, showBridgePhoneSetup]);

  return (
    <>
      {showBridgePhoneSetup && (
        <BridgePhoneSetup
          onSuccess={() => {
            setShowBridgePhoneSetup(false);
          }}
          onError={(error) => {
            handleError(error);
            setShowBridgePhoneSetup(false);
            setIsSubmitting(false);
          }}
        />
      )}
      <Modal
        isOpen={showPermissionModal}
        title="Allow Bridge access"
        onRequestClose={handlePermissionModalClose}
        showCloseBtn={true}
        className="modal--permission"
      >
        {getPermissionModalContent()}
      </Modal>
      <Modal
        isOpen={showLoginPhoneModalState}
        title={"Log in to a phone"}
        showCloseBtn={true}
        onRequestClose={onCloseLoginPhoneModal}
        theme="studio"
        className={
          visibleContent === LoginPhoneModalVisibleContent.start
            ? ""
            : "modal--wide"
        }
      >
        {getContent()}
      </Modal>
    </>
  );
};

export default LoginPhoneModal;
