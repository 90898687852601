import * as React from "react";
import DialerInput from "./DialerInput/DialerInput";
import DialerNumpad from "./DialerNumpad/DialerNumpad";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faShare } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faShareAll } from "@fortawesome/pro-solid-svg-icons/faShareAll";
import "./Dialer.scss";

import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { dialNumber, notificationShow } from "src/store/actions";

import { dialNumberWebrtc, sendDtmf } from "src/store/actions/calls";
import { handleError } from "src/utils/errorHandler";
import { notificationsDismissAll } from "src/store/actions/notifications";
import { TrackAction, TrackCategory } from "src/utils/track";
import { BridgeColor } from "src/utils/consts";
import { useEffect, useState } from "react";
import { INotification } from "src/store/reducers/notifications";
import { usePrevious } from "src/utils/hooks";
import { createSelector } from "reselect";
import { DialerWebrtcContext } from "src/providers/DialerWebrtcContext";

const LAST_NUMBER_STORAGE_KEY = "lastNumber";
const TRACK_CATEGORY = TrackCategory.dialer;

export enum DialerType {
  default = "default",
  transfer = "transfer",
  dtmf = "dtmf",
}
const selectCallsIsLoading = (state: IRootState) =>
  state.calls.actionsInProgress > 0;
const selectDesiredTransferCallId = (state: IRootState) =>
  state.calls.desiredTransferCallId;
const selectOnline = (state: IRootState) => state.window.online;
const selectPhone = (state: IRootState) => state.auth.phone;

const appDataSelector = createSelector(
  [
    selectCallsIsLoading,
    selectDesiredTransferCallId,
    selectOnline,
    selectPhone,
  ],
  (callsIsLoading, desiredTransferCallId, online, phone) => ({
    callsIsLoading,
    desiredTransferCallId,
    online,
    phone,
  })
);
const Dialer: React.FC<{
  type: DialerType;
  callIdForDtmf?: string;
  dark?: boolean;
  disabled?: boolean;
  onAttendedTransfer?: (destination: string) => void;
  onDirectTransfer?: (destination: string) => void;
}> = ({
  type,
  callIdForDtmf,
  dark,
  disabled,
  onAttendedTransfer,
  onDirectTransfer,
}) => {
  const { callsIsLoading, desiredTransferCallId, online, phone } =
    useSelector(appDataSelector);
  const { dialer } = React.useContext(DialerWebrtcContext);
  const dispatch = useDispatch();
  const [number, setNumber] = useState("");
  const [lastNumber, setLastNumber] = useState(
    sessionStorage.getItem(LAST_NUMBER_STORAGE_KEY) || ""
  );
  const [actionButtonsHighlighted, setActionButtonsHighlighted] =
    useState(false);

  const onNotificationShow = (
    notification:
      | INotification
      | Pick<INotification, "message" | "autoDismiss" | "dismissable" | "level">
  ) => dispatch(notificationShow(notification));
  const onNotificationsDismissAll = () => dispatch(notificationsDismissAll());

  const onDialNumber = (destination: string) => {
    if (phone?.isBridgePhone) {
      return dispatch<any>(dialNumberWebrtc(destination, dialer));
    } else {
      return dispatch<any>(dialNumber(destination));
    }
  };

  const onSendDtmf = (callId: string, dtmf: string) =>
    dispatch<any>(sendDtmf(callId, dtmf));

  const onInputSubmit = () => {
    switch (type) {
      case DialerType.default:
        onDialClick();
        break;
      case DialerType.transfer:
        transferInputSubmitHandler();
        break;
    }
  };

  const $getDialButton = () => {
    return (
      <Button
        className="button--dialpad"
        color={BridgeColor.green500}
        fill={"fade"}
        onClick={onDialClick}
        disabled={
          disabled || (!number && !lastNumber) || callsIsLoading || !online
        }
        track={[TRACK_CATEGORY, TrackAction.dialerDial]}
      >
        <FontAwesomeIcon icon={faPhone} />
      </Button>
    );
  };

  const $getDirectTransferBtn = () => {
    return (
      <Button
        className="button--dialpad"
        color={dark ? BridgeColor.gs800 : BridgeColor.gs300}
        onClick={
          onDirectTransfer ? onDirectTransfer.bind(null, number) : undefined
        }
        disabled={
          disabled ||
          !number ||
          !desiredTransferCallId ||
          callsIsLoading ||
          !online
        }
        tooltip={"Direct transfer"}
        track={[TRACK_CATEGORY, TrackAction.dialerTransfer]}
      >
        <FontAwesomeIcon icon={faShare} />
      </Button>
    );
  };

  const $getAttendedTransferBtn = () => {
    return (
      <Button
        className="button--dialpad"
        color={dark ? BridgeColor.gs800 : BridgeColor.gs300}
        onClick={
          onAttendedTransfer ? onAttendedTransfer.bind(null, number) : undefined
        }
        tooltip={"Attended transfer"}
        disabled={disabled || !number || callsIsLoading || !online}
        track={[TRACK_CATEGORY, TrackAction.dialerAttendedTransfer]}
      >
        <FontAwesomeIcon icon={faShareAll} />
      </Button>
    );
  };

  const transferInputSubmitHandler = () => {
    onNotificationsDismissAll();
    onNotificationShow({
      message: "Please choose what type of transfer you want to make.",
      level: "info",
      autoDismiss: 3000,
    });
    setActionButtonsHighlighted(true);
    setTimeout(() => {
      setActionButtonsHighlighted(false);
    }, 300);
  };

  const onDialClick = () => {
    if (!number) {
      return setNumber(lastNumber);
    }
    onDialNumber(number).then(clearNumberInput, handleError);
  };

  const clearNumberInput = () => {
    sessionStorage.setItem(LAST_NUMBER_STORAGE_KEY, number);
    setNumber("");
    setLastNumber(number);
  };

  const onDialerInputChange = (value: string) => {
    setNumber(value);
  };

  const onDialerInputKeypress = (key: string) => {
    if (type === DialerType.dtmf && callIdForDtmf) {
      onSendDtmf(callIdForDtmf, key).catch(handleError);
    }
  };

  const onDialerKeypressHandler = (value: string) => {
    if (type === DialerType.dtmf && callIdForDtmf) {
      onSendDtmf(callIdForDtmf, value).catch(handleError);
    }
    setNumber(number + value);
  };

  const buttonsCssClasses = ["dialer__buttons"];
  if (actionButtonsHighlighted) {
    buttonsCssClasses.push("dialer__buttons--highlight");
  }
  const disableInput = type === DialerType.dtmf && callsIsLoading;

  const typePrev = usePrevious(type);
  const callIdForDtmfPrev = usePrevious(callIdForDtmf);
  useEffect(() => {
    if (number && (type !== typePrev || callIdForDtmf !== callIdForDtmfPrev)) {
      setNumber("");
    }
  }, [callIdForDtmf, callIdForDtmfPrev, number, type, typePrev]);

  return (
    <>
      <div className="dialer">
        <DialerInput
          value={number}
          changed={onDialerInputChange}
          keypress={onDialerInputKeypress}
          dark={dark}
          submit={onInputSubmit}
          disable={disableInput}
          disablePaste={type === DialerType.dtmf}
          placeholder={type === DialerType.dtmf ? "" : "+48 123 123 123"}
        />
        <DialerNumpad
          onKeypress={onDialerKeypressHandler}
          currentValue={number}
          dark={dark}
          disabled={disableInput}
        />
        {type === DialerType.dtmf ? null : (
          <div className={buttonsCssClasses.join(" ")}>
            {type === DialerType.default ? $getDialButton() : null}
            {type === DialerType.transfer ? (
              <>
                {$getDirectTransferBtn()}
                {$getAttendedTransferBtn()}
              </>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default Dialer;
