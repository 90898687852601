import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faThumbtack as faThumbtackRegular } from "@fortawesome/pro-regular-svg-icons/faThumbtack";
import Button from "src/components/UI/Button/Button";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
} from "src/components/UI/List/";
import {
  getUserStatusInfo,
  UserStatus,
  UserStatusInfo,
  getUserStatusDisplay,
} from "src/utils/user";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import "./ContactItem.scss";
import { cssSafeStr } from "src/utils";
import { IContact, ContactType } from "src/store/reducers/contacts";

import { dialNumber } from "src/store/actions";
import { handleError } from "src/utils/errorHandler";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { TrackCategory, TrackAction, trackEvent } from "src/utils/track";
import CallDetailCounter, {
  CallDetailCounterType,
} from "../CallDetailCounter/CallDetailCounter";
import { pinContact, unpinContact } from "src/store/actions/contacts";
import { BridgeColor } from "src/utils/consts";
import { useInfiniteComponentItem } from "src/utils/useInfiniteComponentItem";
import { Fragment } from "react";
import { createSelector } from "reselect";
import { DialerWebrtcContext } from "src/providers/DialerWebrtcContext";
import { dialNumberWebrtc } from "src/store/actions/calls";
const TRACK_CATEGORY = TrackCategory.contactItem;
const appDataSelector = createSelector(
  (state: IRootState, id: string) => state.contacts.compassItems[id],
  (state: IRootState, id: string) => state.contacts.addressBookItems[id],
  (state: IRootState) => state.auth.connection,
  (state: IRootState) => state.window.online,
  (state: IRootState) => state.auth.phone,
  (contactCompass, contactAddressBook, connectionState, online, phone) => ({
    contact: contactCompass || contactAddressBook,
    connectionState,
    online,
    phone,
  })
);
const ContactItem: React.FC<{
  type?: "contact" | "agent";
  id: string;
  isActive?: boolean;
  onClick?: (id: IContact["id"]) => void;
  className?: string;
  onDetailsToggle?: (id: IContact["id"]) => void;
  showPinBtn?: boolean;
  buttons?: React.ReactElement[];
  disableSkeleton?: boolean;
}> = ({
  type,
  id,
  isActive,
  onClick,
  className,
  onDetailsToggle,
  showPinBtn,
  buttons,
  disableSkeleton,
}) => {
  const { contact, connectionState, online, phone } = useSelector(
    (state: IRootState) => appDataSelector(state, id)
  );

  const dispatch = useDispatch();
  const { dialer } = React.useContext(DialerWebrtcContext);
  const elementId = `contact-row--${cssSafeStr(id)}`;
  const { visible } = useInfiniteComponentItem({ elementId, disableSkeleton });
  let userStatusInfo: UserStatusInfo | null = null;

  if (contact.type === ContactType.compass) {
    const user = connectionState?.model.users[contact.id];
    if (user) {
      userStatusInfo = getUserStatusInfo(user, {
        considerRingingQueueCalls: type === "agent",
      });
    }
  }

  const contactType = contact.type;
  const contactName = contact.name;
  const contactCompany = contact.company;
  const contactFirstPhoneNumber = contact.phones.length
    ? contact.phones[0].value
    : null;
  const status = userStatusInfo ? userStatusInfo.userStatus : null;
  const statusDisplay = getUserStatusDisplay(id, {
    considerRingingQueueCalls: type === "agent",
  });
  const statusCallId =
    userStatusInfo && userStatusInfo.call ? userStatusInfo.call.id : null;
  const isPinned = useSelector((state: IRootState) =>
    state.contacts.pinned.includes(id)
  );

  const onDialNumber = async (destination: string) => {
    if (phone?.isBridgePhone) {
      return dispatch<any>(dialNumberWebrtc(destination, dialer));
    } else {
      return dispatch<any>(dialNumber(destination));
    }
  };

  const onPinContact = async (contactId: string) => {
    return dispatch<any>(pinContact(contactId));
  };

  const onUnpinContact = (contactId: string) => {
    return dispatch<any>(unpinContact(contactId));
  };

  const onClickContext = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const $getRowContent = () => {
    if (!visible) {
      return (
        <div className="contact-item__invisible">
          <div className="contact-item__invisible-name" />
          <div className="contact-item__invisible-buttons" />
        </div>
      );
    }
    return (
      <>
        <ListRowTitle>
          {/* TODO: status for address book contacts */}
          <div
            className={`contact-item__status contact-item__status--${
              status || "logged-out"
            }`}
          >
            <FontAwesomeIcon icon={faCircle} />
          </div>
          <Tooltip
            enabled={true}
            content={contactName}
            delay={[1000, 0]}
            annotation={true}
          >
            <span className="list__row-title-text">
              {contactName || contactCompany || "Name or company not provided"}
            </span>
          </Tooltip>
        </ListRowTitle>

        {
          <ListRowInfo>
            {status && contactType === ContactType.compass ? (
              <ListRowInfoItem className="contact-item__right-info">
                <div className={"br-text-right br-text-size--s"}>
                  {statusDisplay}
                  {status === UserStatus.inCall && statusCallId ? (
                    <span>
                      &nbsp;(
                      <CallDetailCounter
                        callId={statusCallId}
                        userId={id}
                        type={CallDetailCounterType.userDuration}
                      />
                      )
                    </span>
                  ) : null}
                </div>
              </ListRowInfoItem>
            ) : null}
            {showPinBtn ? (
              <ListRowInfoItem isButton={true}>
                <Tooltip
                  placement="top"
                  content={
                    isPinned
                      ? "Unpin contact from the top"
                      : "Pin contact to the top"
                  }
                >
                  <Button
                    color={BridgeColor.gs800}
                    icononly={true}
                    fill={"clear"}
                    small={true}
                    onClick={togglePin}
                    track={[TRACK_CATEGORY, TrackAction.contactItemPinToggle]}
                  >
                    <FontAwesomeIcon
                      icon={isPinned ? faThumbtack : faThumbtackRegular}
                    />
                  </Button>
                </Tooltip>
              </ListRowInfoItem>
            ) : null}
            {buttons}
            {onDetailsToggle ? (
              <ListRowInfoItem isButton={true} className="br-screen-small">
                <Button
                  color={BridgeColor.gs800}
                  icononly={true}
                  fill={"clear"}
                  small={true}
                  onClick={toggleHandler}
                >
                  <FontAwesomeIcon
                    icon={isActive ? faChevronUp : faChevronDown}
                  />
                </Button>
              </ListRowInfoItem>
            ) : null}
          </ListRowInfo>
        }
      </>
    );
  };

  const toggleHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDetailsToggle) {
      onDetailsToggle(id);
    }
  };

  const togglePin = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    (isPinned ? onUnpinContact(id) : onPinContact(id)).catch(handleError);
  };

  const contextPinToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(TRACK_CATEGORY, TrackAction.contactItemContextPinToggle);
    togglePin(e);
  };

  const contextDialContact = (contact: IContact) => {
    trackEvent(TRACK_CATEGORY, TrackAction.contactItemContextDial);
    if (!contactFirstPhoneNumber) {
      return;
    }
    onDialNumber(contactFirstPhoneNumber).catch(handleError);
  };

  const $getContextMenu = (): React.ReactNode => {
    return (
      <div>
        {
          // TODO: Check with Taras
          // @ts-ignore
          <ContextMenu id={`contact-${id}`} rtl={true}>
            {showPinBtn ? (
              // TODO: Check with Taras
              // @ts-ignore
              <MenuItem onClick={contextPinToggle}>
                {isPinned ? "Unpin" : "Pin"}
              </MenuItem>
            ) : null}
            {
              // TODO: Check with Taras
              // @ts-ignore
              <MenuItem
                disabled={!contactFirstPhoneNumber || !online}
                onClick={contextDialContact}
              >
                Call
              </MenuItem>
            }
          </ContextMenu>
        }
      </div>
    );
  };

  return (
    <Fragment key={id}>
      {
        // TODO: Check with Taras
        // @ts-ignore
        <ContextMenuTrigger id={elementId}>
          <ListRow
            active={isActive}
            onClick={onClickContext}
            className={className}
            id={elementId}
          >
            {$getRowContent()}
          </ListRow>
        </ContextMenuTrigger>
      }
      {visible ? $getContextMenu() : null}
    </Fragment>
  );
};

export default ContactItem;
