import { Session, Notification, Web } from "sip.js";
import { IncomingResponse } from "sip.js/lib/core";
import {
  defaultMediaStreamFactory,
  defaultSessionDescriptionHandlerFactory,
  SessionDescriptionHandler,
  SessionDescriptionHandlerOptions,
  SessionManager,
  SessionManagerOptions,
} from "sip.js/lib/platform/web";
import { Transport } from "sip.js/lib/platform/web/transport";
import { handleError } from "src/utils/errorHandler";
interface AudioConstraints extends MediaTrackConstraints {
  deviceId?: string | { exact: string };
}
export enum CallStatus {
  Dialing,
  Received,
  Active,
  OnHold,
  Ended,
}

declare global {
  interface Window {
    sessionManager: any;
  }
}

export class Dialer {
  remoteAudio: HTMLAudioElement;
  sessionManager: SessionManager;
  sessions: Array<Session>;
  private mediaConstraints: MediaStreamConstraints = {
    audio: true,
    video: false,
  };
  public onStatusChange?: (
    status: CallStatus,
    identity?: string,
    displayName?: string
  ) => void;

  constructor(
    onStatusChange?: (status: CallStatus, identity?: string) => void
  ) {
    this.remoteAudio = new window.Audio();
    this.sessionManager = new SessionManager("wss://");
    this.sessions = new Array<Session>();
    this.onStatusChange = onStatusChange;
  }

  public async setInputDevice(deviceId: string) {
    try {
      this.mediaConstraints = {
        audio: { deviceId: { exact: deviceId } },
        video: false,
      };

      const currentSession = this.sessions[this.sessions.length - 1];
      if (currentSession) {
        const newStream = await navigator.mediaDevices.getUserMedia(
          this.mediaConstraints
        );
        const sessionDescriptionHandler =
          currentSession.sessionDescriptionHandler;

        if (sessionDescriptionHandler) {
          const peerConnection = (sessionDescriptionHandler as any)
            .peerConnection;
          const senders = peerConnection?.getSenders();

          if (senders) {
            for (const sender of senders) {
              if (sender.track?.kind === "audio") {
                const [audioTrack] = newStream.getAudioTracks();
                if (audioTrack) {
                  await sender.replaceTrack(audioTrack);
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error setting input device:", error);
      throw error;
    }
  }

  public async setOutputDevice(deviceId: string) {
    try {
      // Check if setSinkId is supported
      if (!("setSinkId" in HTMLAudioElement.prototype)) {
        throw new Error("Audio output device selection is not supported");
      }

      // Validate if device exists
      const devices = await navigator.mediaDevices.enumerateDevices();
      const deviceExists = devices.some(
        (device) =>
          device.kind === "audiooutput" && device.deviceId === deviceId
      );

      if (!deviceExists) {
        deviceId = "default";
      }

      await (this.remoteAudio as any).setSinkId(deviceId);
      const currentSession = this.sessions[this.sessions.length - 1];
      if (currentSession) {
        const remoteStream =
          this.sessionManager.getRemoteMediaStream(currentSession);
        if (remoteStream) {
          this.remoteAudio.srcObject = null;
          await new Promise((resolve) => setTimeout(resolve, 50));

          this.remoteAudio.srcObject = remoteStream;
          try {
            await this.remoteAudio.play();
          } catch (playError) {
            this.remoteAudio.srcObject = remoteStream;
          }
        }
      }
    } catch (error) {
      console.error("Error setting output device:", error);
      throw error;
    }
  }

  public async login(
    server: string,
    aor: string,
    password: string,
    onReject?: (res: IncomingResponse) => void
  ) {
    const sessionManagerOptions = this.createSessionManagerOptions(
      server,
      aor,
      password
    );
    this.sessionManager = new Web.SessionManager(server, sessionManagerOptions);

    window.sessionManager = this.sessionManager;

    try {
      await this.sessionManager.connect();
    } catch (error) {
      onReject?.(error);
    }
    await this.sessionManager.register({
      requestDelegate: {
        onReject,
      },
    });
  }
  public async logout(): Promise<void> {
    try {
      const promises: Promise<void>[] = [];
      if (this.sessions.length > 0) {
        promises.push(
          ...this.sessions.map((session) =>
            this.sessionManager
              .hangup(session)
              .catch((err) => console.warn("Hangup error (ignored):", err))
          )
        );
        this.sessions = [];
      }
      promises.push(
        this.sessionManager
          .unregister()
          .catch((err) => console.warn("Unregister error (ignored):", err))
      );
      promises.push(
        this.sessionManager
          .disconnect()
          .catch((err) => console.warn("Disconnect error (ignored):", err))
      );

      await Promise.all(promises);

      this.sessionManager = new SessionManager("wss://");
      if (window.sessionManager === this.sessionManager) {
        window.sessionManager = undefined;
      }
    } catch (error) {
      console.error("Logout error:", error);
      if (
        !(
          error instanceof Error &&
          error.message.includes("request already in progress")
        )
      ) {
        handleError(error);
      }
    }
  }
  public async call(number: string) {
    try {
      // Validate current audio device before making call
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioConstraints = this.mediaConstraints.audio as AudioConstraints;

      const deviceExists = devices.some((device) => {
        const constraintDeviceId = audioConstraints.deviceId;
        if (
          typeof constraintDeviceId === "object" &&
          "exact" in constraintDeviceId
        ) {
          return device.deviceId === constraintDeviceId.exact;
        }
        return device.deviceId === constraintDeviceId;
      });

      // Reset constraints if device not found
      if (!deviceExists) {
        this.mediaConstraints = {
          audio: true,
          video: false,
        };

        // Update session manager media constraints
        if (this.sessionManager) {
          (this.sessionManager as any).options.media.constraints = {
            audio: true,
            video: false,
          };
        }
      }

      if (this.sessions.length > 0) {
        this.sessionManager.hold(this.sessions[this.sessions.length - 1]);
      }

      const rawNumber = "sip:" + number + "@webrtc-client";
      this.setCallStatus(CallStatus.Active, rawNumber, number);

      try {
        await this.sessionManager.call(rawNumber);
      } catch (callError) {
        if (
          callError instanceof Error &&
          callError.name === "OverconstrainedError"
        ) {
          // Fallback to default constraints if still failing
          this.mediaConstraints = {
            audio: true,
            video: false,
          };
          (this.sessionManager as any).options.media.constraints = {
            audio: true,
            video: false,
          };
          await this.sessionManager.call(rawNumber);
        } else {
          throw callError;
        }
      }
    } catch (error) {
      console.error("Error making call:", error);
      throw error;
    }
  }

  public hasActiveSession(): boolean {
    return this.sessions.length > 0;
  }

  public async hangup() {
    const lastSession = this.sessions[this.sessions.length - 1];

    if (!lastSession) {
      this.setCallStatus(CallStatus.Ended);
      this.remoteAudio.pause();
      this.remoteAudio.currentTime = 0;
      return;
    }

    try {
      await this.sessionManager.hangup(lastSession);
      this.remoteAudio.pause();
      this.remoteAudio.currentTime = 0;
    } catch (error) {
      this.sessions.pop();
      this.setCallStatus(CallStatus.Ended);
      this.remoteAudio.pause();
      this.remoteAudio.currentTime = 0;
      console.error("Error hanging up call:", error);
    }
  }

  public async answer(session?: Session) {
    if (this.sessions.length === 0) {
      throw new Error("No callers right now");
    }
    if (!session) {
      session = this.sessions[this.sessions.length - 1];
    }

    try {
      // Hold all existing calls before answering new one
      const holdPromises = this.sessions
        .filter((s) => s !== session && s.state === "Established")
        .map((s) => this.sessionManager.hold(s));

      await Promise.all(holdPromises);

      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioConstraints = this.mediaConstraints.audio as AudioConstraints;

      const isDeviceIdObject = (
        deviceId: string | { exact: string } | undefined
      ): deviceId is { exact: string } => {
        return typeof deviceId === "object" && "exact" in deviceId;
      };

      const audioInputExists = devices.some((device) => {
        const constraintDeviceId = audioConstraints.deviceId;
        if (isDeviceIdObject(constraintDeviceId)) {
          return device.deviceId === constraintDeviceId.exact;
        }
        return device.deviceId === constraintDeviceId;
      });

      if (!audioInputExists) {
        this.mediaConstraints = {
          audio: true,
          video: false,
        };
      }

      if (session.sessionDescriptionHandler) {
        (session.sessionDescriptionHandler as any).options = {
          constraints: this.mediaConstraints,
        };
      }

      await this.sessionManager.answer(session);
      this.setCallStatus(
        CallStatus.Active,
        session.remoteIdentity.uri.toRaw(),
        session.remoteIdentity.displayName
      );
    } catch (error) {
      console.error("Error answering call:", error);
      if (error instanceof Error && error.name === "OverconstrainedError") {
        this.mediaConstraints = {
          audio: true,
          video: false,
        };
        await this.sessionManager.answer(session);
        this.setCallStatus(
          CallStatus.Active,
          session.remoteIdentity.uri.toRaw(),
          session.remoteIdentity.displayName
        );
      } else {
        throw error;
      }
    }
  }

  public hold(session?: Session) {
    if (this.sessions.length === 0) {
      throw new Error("No callers right now");
    }
    if (!session) {
      session = this.sessions[this.sessions.length - 1];
    }
    this.sessionManager.hold(session);
    this.setCallStatus(CallStatus.OnHold);
  }

  public unhold(session?: Session) {
    if (this.sessions.length === 0) {
      throw new Error("No callers right now");
    }
    if (!session) {
      session = this.sessions[this.sessions.length - 1];
    }
    this.sessionManager.unhold(session);
    this.setCallStatus(CallStatus.Active);
  }

  private createSessionManagerOptions(
    server: string,
    sipAddress: string,
    password: string
  ): SessionManagerOptions {
    return {
      aor: sipAddress,
      delegate: {
        onCallCreated: (session: Session) => {
          this.setupSessionDelegate(session);
          this.sessions.push(session);
          this.setCallStatus(
            CallStatus.Dialing,
            session.remoteIdentity.uri.toRaw(),
            session.remoteIdentity.displayName
          );
        },
        onCallReceived: (session: Session) => {
          this.setupSessionDelegate(session);
          this.setCallStatus(
            CallStatus.Received,
            session.remoteIdentity.uri.toRaw(),
            session.remoteIdentity.displayName
          );
        },
        onCallAnswered: (session: Session) => {
          this.setCallStatus(
            CallStatus.Active,
            session.remoteIdentity.uri.toRaw(),
            session.remoteIdentity.displayName
          );
        },
        onCallHold: (session: Session, isOnHold: boolean) => {
          this.setCallStatus(
            isOnHold ? CallStatus.OnHold : CallStatus.Active,
            session.remoteIdentity.uri.toRaw()
          );
        },
        onCallHangup: (session: Session) => {
          this.sessions.pop();
          this.setCallStatus(
            CallStatus.Ended,
            session.remoteIdentity.uri.toRaw()
          );
        },
      },
      media: {
        remote: {
          audio: this.remoteAudio,
        },
        constraints: {
          audio: this.mediaConstraints.audio as any,
          video: false,
        },
      },
      userAgentOptions: {
        authorizationPassword: password,
        transportConstructor: Transport,
        transportOptions: {
          server,
        },
        sessionDescriptionHandlerFactory: this.sdpHandlerFactory(),
      },
    };
  }

  private sdpHandlerFactory() {
    const mediaStreamFactory = (
      constraints: MediaStreamConstraints,
      sessionDescriptionHandler: SessionDescriptionHandler,
      options?: SessionDescriptionHandlerOptions
    ) => {
      sessionDescriptionHandler.peerConnectionDelegate = {
        onicecandidate: (_) => {
          sessionDescriptionHandler.iceGatheringComplete();
        },
      };
      return defaultMediaStreamFactory()(
        constraints,
        sessionDescriptionHandler,
        options
      );
    };

    return defaultSessionDescriptionHandlerFactory(mediaStreamFactory);
  }

  private setupSessionDelegate(session: Session) {
    const delegate = {
      onNotify: (notification: Notification) =>
        this.handleNotify(session, notification),
      onTerminate: () => {
        this.sessions = this.sessions.filter((s) => s !== session);
        this.setCallStatus(CallStatus.Ended);
        if (this.remoteAudio) {
          this.remoteAudio.pause();
          this.remoteAudio.currentTime = 0;
        }
      },
    };

    session.delegate = delegate;

    if (session.dialog) {
      session.dialog.delegate = {
        onNotify: (notification) =>
          this.handleNotify(session, new Notification(notification)),
      };
    }
  }
  private handleNotify(session: Session, notification: Notification) {
    const responseOptions = {
      statusCode: 200,
      extraHeaders: new Array<string>(),
    };

    const eventType = notification.request.getHeader("Event");
    switch (eventType) {
      case "hold":
        this.sessionManager.hold(session);
        break;

      case "talk":
        if (session.state === "Initial") {
          this.answer(session);
          const routeHeaders = notification.request.getHeaders("Record-Route");
          routeHeaders.forEach((route) => {
            responseOptions.extraHeaders.push(`Record-Route: ${route}`);
          });
        } else {
          this.sessionManager.unhold(session);
        }
        break;

      default:
        break;
    }

    notification.accept(responseOptions);
  }

  private setCallStatus(
    status: CallStatus,
    identity?: string,
    displayName?: string
  ) {
    this.onStatusChange?.(status, identity, displayName);
  }
}
