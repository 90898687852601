import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CallStatus, Dialer } from "src/utils/dialerWebrtc";
import { IUserPreferences } from "src/store/preferences";
import { IRootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { handleError, wrapApiError } from "src/utils/errorHandler";
import { SipConnectionService } from "src/services/sipConnection";
import { fetchBridgePhones } from "src/utils/user";
import { handleCallingWithBridgeError } from "src/store/actions/auth";
import { UserFeature } from "src/store/reducers/auth";
import { logoutUserFromPhone } from "src/store/actions/auth";
import { isElectron, isMacOS } from "src/utils";
import { notificationShow } from "src/store/actions";

export type Item = {
  label: string;
  value?: string;
  deviceId?: string;
};

export interface PhoneDetails {
  id: string;
  password: string;
  baseDomain: string;
}

export interface CallDetails {
  status: CallStatus;
  identity: string;
  displayName?: string;
}
export enum PermissionStatus {
  granted = "granted",
  denied = "denied",
  pending = "pending",
}

interface DialerProviderParams {
  setupDialer: (detail: PhoneDetails) => void;
  loginError: boolean;
  isLoggedIn: boolean;
  dialer?: Dialer;
  deviceInputId?: string;
  deviceOutputId?: string;
  inputDevice?: Item;
  setInputDevice: (device: Item) => void;
  outputDevice?: Item;
  setOutputDevice: (device: Item) => void;
  setAudioOutputDevice: (deviceId: string) => void;
  setAudioInputDevice: (deviceId: string) => void;
  calls: CallDetails[];
  currentOutputDeviceId: string | undefined;
  getDevices: () => Promise<void>;
  requestPermission: () => Promise<void>;
  permissionStatus: PermissionStatus | null;
  inputDevices: Item[];
  outputDevices: Item[];
  hasBridgePhones: boolean;
  hasCallingWithBridgeFeature: boolean;
}
interface DialerWebrtcProviderProps extends PropsWithChildren {
  userPreferences: IUserPreferences;
}
const DialerWebrtcContext = createContext<DialerProviderParams>({
  setupDialer: () => {},
  loginError: false,
  isLoggedIn: false,
  setInputDevice: () => {},
  setOutputDevice: () => {},
  setAudioOutputDevice: () => {},
  setAudioInputDevice: () => {},
  calls: [],
  currentOutputDeviceId: undefined,
  getDevices: async () => {},
  requestPermission: async () => {},
  permissionStatus: null,
  inputDevices: [],
  outputDevices: [],
  hasBridgePhones: false,
  hasCallingWithBridgeFeature: false,
});
interface DialerWebrtcProviderProps extends PropsWithChildren {}

const DialerWebrtcProvider: React.FC<DialerWebrtcProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const { user, connection, onboardingMode } = useSelector(
    (state: IRootState) => ({
      user: state.auth.user,
      connection: state.auth.connection,
      onboardingMode: state.auth.onboardingMode,
    })
  );
  const userPreferences = useSelector(
    (state: IRootState) => state.preferences.user
  );
  const phone = useSelector((state: IRootState) => state.auth.phone);

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dialer, setDialer] = useState<Dialer>();
  const [calls, setCalls] = useState<CallDetails[]>([]);
  const [inputDevice, setInputDevice] = useState<Item>();
  const [outputDevice, setOutputDevice] = useState<Item>();
  const [currentOutputDeviceId, setCurrentOutputDeviceId] = useState<
    string | undefined
  >(userPreferences.defaultAudioDevice);
  const [permissionStatus, setPermissionStatus] =
    useState<PermissionStatus | null>(null);
  const [inputDevices, setInputDevices] = useState<Item[]>([]);
  const [outputDevices, setOutputDevices] = useState<Item[]>([]);
  const [hasBridgePhones, setHasBridgePhones] = useState(false);
  const [hasCallingWithBridgeFeature, setHasCallingWithBridgeFeature] =
    useState(false);

  const permissionCheckInProgress = useRef(false);
  const lastNotificationTime = useRef<number>(0);

  const setAudioInputDevice = useCallback(
    (deviceId: string) => {
      dialer?.setInputDevice(deviceId);
    },
    [dialer]
  );

  const setAudioOutputDevice = useCallback(
    (deviceId: string) => {
      setCurrentOutputDeviceId(deviceId);
      dialer?.setOutputDevice(deviceId);
    },
    [dialer]
  );

  const getDevicesMacOs = useCallback(async () => {
    if (permissionCheckInProgress.current) return;

    try {
      permissionCheckInProgress.current = true;

      // Add delay before checking system permissions
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Check system permissions first
      const systemStatus = await window.electronAPI.getMediaAccessStatus();

      // If denied, request access explicitly
      if (systemStatus !== "granted") {
        await window.electronAPI.requestMediaAccess(); // Add this IPC handler

        // Re-check status after request
        const newStatus = await window.electronAPI.getMediaAccessStatus();
        if (newStatus !== "granted") {
          setPermissionStatus(PermissionStatus.denied);
          setInputDevices([]);
          setOutputDevices([]);
          return;
        }
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      setPermissionStatus(PermissionStatus.granted);
      stream.getTracks().forEach((track) => track.stop());

      const devices = await navigator.mediaDevices.enumerateDevices();
      const inputs = devices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          label: device.label || "Microphone",
          deviceId: device.deviceId,
        }));
      const outputs = devices
        .filter((device) => device.kind === "audiooutput")
        .map((device) => ({
          label: device.label || "Speaker",
          deviceId: device.deviceId,
        }));

      setInputDevices(inputs);
      setOutputDevices(outputs);
    } catch (error) {
      setPermissionStatus(PermissionStatus.denied);
      setInputDevices([]);
      setOutputDevices([]);
    } finally {
      permissionCheckInProgress.current = false;
    }
  }, []);

  const getDevicesWindowsAndWeb = useCallback(async () => {
    if (permissionCheckInProgress.current) return;

    try {
      permissionCheckInProgress.current = true;

      // Try to get user media access
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Set permission granted and cleanup stream
      setPermissionStatus(PermissionStatus.granted);
      stream.getTracks().forEach((track) => track.stop());

      // Enumerate devices
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Filter and map input devices
      const inputs = devices
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          label: device.label || "Microphone",
          deviceId: device.deviceId,
        }));

      // Filter and map output devices
      const outputs = devices
        .filter((device) => device.kind === "audiooutput")
        .map((device) => ({
          label: device.label || "Speaker",
          deviceId: device.deviceId,
        }));

      setInputDevices(inputs);
      setOutputDevices(outputs);
    } catch (error) {
      // Handle permission denied
      setPermissionStatus(PermissionStatus.denied);
      setInputDevices([]);
      setOutputDevices([]);
    } finally {
      permissionCheckInProgress.current = false;
    }
  }, []);

  const getDevices = useCallback(async () => {
    if (!phone?.isBridgePhone) {
      return;
    }
    if (isElectron() && isMacOS()) {
      await getDevicesMacOs();
    } else {
      await getDevicesWindowsAndWeb();
    }
  }, [getDevicesMacOs, getDevicesWindowsAndWeb, phone?.isBridgePhone]);

  const requestPermission = useCallback(async () => {
    if (!phone?.isBridgePhone) {
      return;
    }
    try {
      await new Promise<void>((resolve) => {
        setPermissionStatus(PermissionStatus.pending);
        setTimeout(resolve, 0);
      });

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      stream.getTracks().forEach((track) => {
        track.stop();
      });

      await new Promise<void>((resolve) => {
        setPermissionStatus(PermissionStatus.granted);
        setTimeout(resolve, 0);
      });
      await getDevices();
    } catch (error) {
      await new Promise<void>((resolve) => {
        setPermissionStatus(PermissionStatus.denied);
        setTimeout(resolve, 0);
      });
    }
  }, [getDevices, phone?.isBridgePhone]);
  const handleLogout = useCallback(
    async (isSync?: boolean) => {
      if (!dialer || !isLoggedIn || isLoggingOut) return;

      try {
        setIsLoggingOut(true);

        if (user?.id) {
          if (isSync) {
            // Synchronous logout for tab close
            const logoutPromise = dispatch(logoutUserFromPhone(user.id) as any);
            const dialerLogoutPromise = dialer.logout();

            // Wait for both operations to complete
            await Promise.all([logoutPromise, dialerLogoutPromise]);
          } else {
            await dispatch(logoutUserFromPhone(user.id) as any);
            await dialer.logout();
          }
        }

        setIsLoggedIn(false);
        setDialer(undefined);
        setCalls([]);
      } catch (error) {
        console.error("Logout error:", error);
      } finally {
        setIsLoggingOut(false);
      }
    },
    [dialer, isLoggedIn, user?.id, dispatch, isLoggingOut]
  );

  const onCallstatusChange = useCallback(
    (status: CallStatus, identity?: string, displayName?: string) => {
      if (!identity) {
        return;
      }

      switch (status) {
        case CallStatus.Ended:
          setCalls((prev) => prev.filter((item) => item.identity !== identity));
          break;
        default:
          setCalls((prev) => {
            const result = [...prev];
            const currentCallIndex = prev.findIndex(
              (item) => item.identity === identity
            );
            if (currentCallIndex === -1) {
              result.push({
                identity,
                status,
                displayName,
              });
            } else {
              result[currentCallIndex].status = status;
            }
            return result;
          });
          break;
      }
    },
    []
  );

  const setupDialer = useCallback(
    ({ id, password, baseDomain }: PhoneDetails) => {
      if (!dialer) {
        const newDialer = new Dialer(onCallstatusChange);
        setDialer(newDialer);

        // Wait for dialer to be set before continuing
        setTimeout(() => {
          const webSocketServer =
            SipConnectionService.getWebSocketUrl(baseDomain);
          const aor = SipConnectionService.getSipAddress({ id, baseDomain });
          newDialer
            .login(webSocketServer, aor, password, (reject) => {
              setLoginError(true);
              setIsLoggedIn(false);
            })
            .then(() => {
              setIsLoggedIn(true);
              setLoginError(false);
            });
        }, 0);
      } else {
        const webSocketServer =
          SipConnectionService.getWebSocketUrl(baseDomain);
        const aor = SipConnectionService.getSipAddress({ id, baseDomain });
        dialer
          .login(webSocketServer, aor, password, (reject) => {
            setLoginError(true);
            setIsLoggedIn(false);
          })
          .then(() => {
            setIsLoggedIn(true);
            setLoginError(false);
          });
      }
    },
    [dialer, onCallstatusChange]
  );

  const checkBridgePhoneAvailability = useCallback(async () => {
    if (!connection || !user?.id) {
      setHasBridgePhones(false);
      return;
    }

    try {
      const bridgePhones = await fetchBridgePhones(Number(user.id), connection);
      const actualBridgePhones = bridgePhones.filter(
        (phone) => phone.isBridgePhone === true
      );
      setHasBridgePhones(actualBridgePhones.length > 0);

      if (phone?.isBridgePhone && actualBridgePhones.length === 0) {
        dispatch(
          handleCallingWithBridgeError(
            "Your Bridge phone has been deleted. Contact an administrator for help."
          ) as any
        );
        await handleLogout();
      }
    } catch (error) {
      setHasBridgePhones(false);
      handleError(error);
    }
  }, [connection, user?.id, phone?.isBridgePhone, dispatch, handleLogout]);

  const checkBridgeFeatureAvailability = useCallback(async () => {
    if (!connection || !user?.id) {
      setHasCallingWithBridgeFeature(false);
      return;
    }

    try {
      const features = await wrapApiError<Array<{ id: UserFeature }>>(
        connection.rest.get(
          `${connection.rest.getUrlForObject(
            "user",
            parseInt(user.id, 10)
          )}/features`
        )
      );

      const hasBridgeFeature = features.some(
        (f) => f.id === UserFeature.callingwbridge
      );
      setHasCallingWithBridgeFeature(hasBridgeFeature);

      if (phone?.isBridgePhone && !hasBridgeFeature) {
        dispatch(
          handleCallingWithBridgeError(
            "Your access to 'Calling with Bridge' has been revoked."
          ) as any
        );
        await handleLogout();
      }
    } catch (error) {
      setHasCallingWithBridgeFeature(false);
      handleError(error);
    }
  }, [connection, user?.id, phone?.isBridgePhone, dispatch, handleLogout]);

  //Check audio permission
  useEffect(() => {
    const checkAudioPermission = async (): Promise<boolean> => {
      if (isElectron()) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          stream.getTracks().forEach((track) => track.stop());
          setPermissionStatus(PermissionStatus.granted);
          return true;
        } catch (error) {
          setPermissionStatus(PermissionStatus.denied);
          return false;
        }
      } else {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          const hasAudioInput = devices.some(
            (device) => device.kind === "audioinput" && device.label !== ""
          );

          if (hasAudioInput) {
            setPermissionStatus(PermissionStatus.granted);
            return true;
          }
          await requestPermission();
          return true;
        } catch (error) {
          setPermissionStatus(PermissionStatus.denied);
          return false;
        }
      }
    };
    const checkPermissions = async () => {
      if (phone?.isBridgePhone) {
        const hasPermission = await checkAudioPermission();
        if (hasPermission) {
          await getDevices();
        }
      }
    };

    checkPermissions();
  }, [getDevices, phone?.isBridgePhone, requestPermission]);

  // Set default devices
  useEffect(() => {
    const initializeDefaultDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const defaultInput = devices.find(
        (device) =>
          device.kind === "audioinput" && device.deviceId === "default"
      );
      const defaultOutput = devices.find(
        (device) =>
          device.kind === "audiooutput" && device.deviceId === "default"
      );

      if (defaultInput && !inputDevice) {
        setInputDevice(defaultInput);
        setAudioInputDevice(defaultInput.deviceId);
      }

      if (defaultOutput && !outputDevice) {
        setOutputDevice(defaultOutput);
        setAudioOutputDevice(defaultOutput.deviceId);
      }
    };
    const setupDevices = async () => {
      if (
        phone?.isBridgePhone &&
        permissionStatus === PermissionStatus.granted
      ) {
        await initializeDefaultDevices();
      }
    };

    setupDevices();
  }, [
    phone?.isBridgePhone,
    permissionStatus,
    inputDevice,
    outputDevice,
    setAudioInputDevice,
    setAudioOutputDevice,
  ]);

  // Set default devices
  useEffect(() => {
    if (userPreferences.defaultAudioDevice && phone?.isBridgePhone) {
      setCurrentOutputDeviceId(userPreferences.defaultAudioDevice);
      dialer?.setOutputDevice(userPreferences.defaultAudioDevice);
    }

    if (userPreferences.defaultMicrophone && phone?.isBridgePhone) {
      dialer?.setInputDevice(userPreferences.defaultMicrophone);
    }
  }, [
    dialer,
    phone?.isBridgePhone,
    userPreferences.defaultAudioDevice,
    userPreferences.defaultMicrophone,
  ]);

  // Check bridge phone availability
  useEffect(() => {
    let interval: NodeJS.Timer | null = null;

    const runChecks = async () => {
      if (!connection || !user?.id || onboardingMode) {
        return;
      }

      try {
        await checkBridgeFeatureAvailability();
        await checkBridgePhoneAvailability();
      } catch (error) {
        console.warn("Bridge checks failed:", error);
      }
    };

    if (connection && user?.id && !onboardingMode) {
      runChecks();
    }

    if (phone?.isBridgePhone && isLoggedIn && !onboardingMode) {
      interval = setInterval(runChecks, 30000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    connection,
    user?.id,
    phone?.isBridgePhone,
    isLoggedIn,
    checkBridgePhoneAvailability,
    checkBridgeFeatureAvailability,
    onboardingMode,
  ]);

  // Initialize dialer
  useEffect(() => {
    if (isLoggingOut) return;
    const initializeDialer = async () => {
      if (phone?.isBridgePhone && !dialer) {
        const newDialer = new Dialer(onCallstatusChange);
        setDialer(newDialer);
      } else if (!phone?.isBridgePhone) {
        await handleLogout();
      }
    };

    if (phone?.isBridgePhone && !dialer) {
      initializeDialer();
    }
  }, [
    phone?.isBridgePhone,
    dialer,
    handleLogout,
    onCallstatusChange,
    isLoggingOut,
  ]);

  const switchToDefaultDevices = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    // Find default devices
    const defaultInput = devices.find(
      (device) => device.kind === "audioinput" && device.deviceId === "default"
    );
    const defaultOutput = devices.find(
      (device) => device.kind === "audiooutput" && device.deviceId === "default"
    );

    // Update devices if needed
    if (defaultInput && inputDevice?.deviceId !== "default") {
      setInputDevice({
        label: defaultInput.label || "Default Microphone",
        deviceId: defaultInput.deviceId,
      });
      setAudioInputDevice(defaultInput.deviceId);
    }
    if (defaultOutput && outputDevice?.deviceId !== "default") {
      setOutputDevice({
        label: defaultOutput.label || "Default Speaker",
        deviceId: defaultOutput.deviceId,
      });
      setAudioOutputDevice(defaultOutput.deviceId);
    }
  }, [inputDevice, outputDevice, setAudioInputDevice, setAudioOutputDevice]);

  // helper function to prevent two notifications from showing at the same time
  const shouldShowNotification = () => {
    const NOTIFICATION_DEBOUNCE_MS = 1000; // Minimum time between notifications
    const now = Date.now();
    if (now - lastNotificationTime.current > NOTIFICATION_DEBOUNCE_MS) {
      lastNotificationTime.current = now;
      return true;
    }
    return false;
  };

  const handleDeviceChange = useCallback(async () => {
    if (!phone?.isBridgePhone) {
      return;
    }
    if (shouldShowNotification()) {
      dispatch(
        notificationShow({
          message:
            "Your audio devices have changed. Make sure in the settings everything is in order.",
          level: "info",
          dismissable: true,
          autoDismiss: 4000,
        })
      );
    }
    if (permissionStatus === PermissionStatus.granted) {
      await switchToDefaultDevices();

      // Get updated device list
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Update labels for current devices
      if (inputDevice?.deviceId) {
        const updatedInputDevice = devices.find(
          (d) =>
            d.kind === "audioinput" &&
            (d.deviceId === inputDevice.deviceId ||
              (inputDevice.deviceId === "default" && d.deviceId === "default"))
        );

        if (updatedInputDevice) {
          setInputDevice({
            ...inputDevice,
            label: updatedInputDevice.label,
          });
        }
      }

      if (outputDevice?.deviceId) {
        const updatedOutputDevice = devices.find(
          (d) =>
            d.kind === "audiooutput" &&
            (d.deviceId === outputDevice.deviceId ||
              (outputDevice.deviceId === "default" && d.deviceId === "default"))
        );

        if (updatedOutputDevice) {
          setOutputDevice({
            ...outputDevice,
            label: updatedOutputDevice.label,
          });
        }
      }
      await getDevices();
    }
  }, [
    phone?.isBridgePhone,
    permissionStatus,
    dispatch,
    switchToDefaultDevices,
    inputDevice,
    outputDevice,
    getDevices,
  ]);

  // Check for audio permission on mount
  useEffect(() => {
    const checkStoredPermission = async () => {
      // Only check permissions if using bridge phone
      if (phone?.isBridgePhone) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          stream.getTracks().forEach((track) => track.stop());
          setPermissionStatus(PermissionStatus.granted);
        } catch (err) {
          setPermissionStatus(PermissionStatus.denied);
        }
      } else {
        // Reset permission status when not using bridge phone
        setPermissionStatus(null);
      }
    };

    checkStoredPermission();
  }, [phone?.isBridgePhone]);

  // This will trigger the error handler when permissions are denied
  useEffect(() => {
    if (permissionStatus === "denied" && phone?.isBridgePhone) {
      if (shouldShowNotification()) {
        dispatch(
          notificationShow({
            message:
              "Bridge has no permission to use audio devices.\nGo to the Audio Settings in your system and change permision.",
            level: "danger",
            dismissable: true,
            autoDismiss: 4000,
          })
        );
      }
    }
  }, [dispatch, permissionStatus, phone?.isBridgePhone]);

  // Add event listener for device change
  useEffect(() => {
    navigator.mediaDevices?.addEventListener(
      "devicechange",
      handleDeviceChange
    );
    return () => {
      navigator.mediaDevices?.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, [handleDeviceChange]);

  // Logout bridge phone when user closes the tab
  useEffect(() => {
    let isRealUnload = false;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        isRealUnload = true;
      }
    };
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (phone?.isBridgePhone && user?.id && isRealUnload) {
        handleLogout(true);

        // Delay tab close slightly to allow logout
        const start = Date.now();
        while (Date.now() - start < 1000) {
          // Busy wait
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleLogout, phone?.isBridgePhone, user?.id]);

  useEffect(() => {
    // Default no-op cleanup function
    let cleanup = () => {};

    if (isElectron() && window.electronAPI?.ipcRenderer) {
      // Set up quit handler
      window.electronAPI.ipcRenderer.send("setup-quit-handler");

      // Listen for quit events
      const handleQuit = async () => {
        if (phone?.isBridgePhone && user?.id) {
          await handleLogout();
        }
      };

      window.electronAPI.ipcRenderer.on("app-quitting", handleQuit);

      cleanup = () => {
        window.electronAPI?.ipcRenderer.removeListener(
          "app-quitting",
          handleQuit
        );
      };
    }

    return cleanup;
  }, [handleLogout, phone?.isBridgePhone, user?.id]);

  const value: DialerProviderParams = useMemo(
    () => ({
      setupDialer,
      loginError,
      isLoggedIn,
      dialer,
      calls,
      inputDevice,
      setInputDevice,
      outputDevice,
      setOutputDevice,
      setAudioOutputDevice,
      setAudioInputDevice,
      currentOutputDeviceId,
      getDevices,
      requestPermission,
      permissionStatus,
      inputDevices,
      outputDevices,
      hasBridgePhones,
      hasCallingWithBridgeFeature,
    }),
    [
      setupDialer,
      loginError,
      isLoggedIn,
      dialer,
      calls,
      inputDevice,
      setInputDevice,
      outputDevice,
      setOutputDevice,
      setAudioOutputDevice,
      setAudioInputDevice,
      currentOutputDeviceId,
      getDevices,
      requestPermission,
      permissionStatus,
      inputDevices,
      outputDevices,
      hasBridgePhones,
      hasCallingWithBridgeFeature,
    ]
  );

  return (
    <DialerWebrtcContext.Provider value={value}>
      {children}
    </DialerWebrtcContext.Provider>
  );
};

export { DialerWebrtcContext, DialerWebrtcProvider };
