import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./theme/reset.scss";
import "./theme/theme.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { initTracking } from "./utils/track";
import { msalConfig } from "src/msalConfig";

import { Providers } from "@microsoft/mgt";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { isElectron } from "src/utils";
import { DialerWebrtcProvider } from "./providers/DialerWebrtcContext";
Providers.globalProvider = new Msal2Provider(msalConfig);

initTracking();
if (!isElectron()) {
  console.log(`Compass Bridge\nVersion: ${process.env.REACT_APP_BUILD_SHA}`);
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DialerWebrtcProvider userPreferences={store.getState().preferences.user}>
        <App />
      </DialerWebrtcProvider>
    </Provider>
  </React.StrictMode>
);
