import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import "./SettingsAudioForm.scss";
import {
  selectUserPreferences,
  updateUserPreferences,
} from "src/store/preferences";
import { useAppDispatch } from "src/store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { DialerWebrtcContext } from "src/providers/DialerWebrtcContext";
import { Warning } from "../UI/Warning/Warning";
import { isElectron } from "src/utils";

const SettingsAudioForm = () => {
  const {
    getDevices,
    inputDevice,
    outputDevice,
    setInputDevice,
    setOutputDevice,
    setAudioInputDevice,
    setAudioOutputDevice,
    permissionStatus,
    inputDevices,
    outputDevices,
  } = useContext(DialerWebrtcContext);

  const dispatch = useAppDispatch();

  const userPreferences = useSelector(selectUserPreferences);

  const [isAudioDropdownOpen, setIsAudioDropdownOpen] = useState(false);
  const [isMicDropdownOpen, setIsMicDropdownOpen] = useState(false);

  const audioDropdownRef = useRef<HTMLDivElement>(null);
  const micDropdownRef = useRef<HTMLDivElement>(null);

  const toggleAudioDropdown = async () => {
    if (permissionStatus === "granted") {
      await getDevices();
    }
    setIsAudioDropdownOpen(!isAudioDropdownOpen);
  };

  const toggleMicDropdown = async () => {
    if (permissionStatus === "granted") {
      await getDevices();
    }
    setIsMicDropdownOpen(!isMicDropdownOpen);
  };

  const handleInputChange = useCallback(
    (deviceId: string) => {
      const device = inputDevices.find((d) => d.deviceId === deviceId);
      if (device) {
        // Only update if the device is different from current
        if (inputDevice?.deviceId !== device.deviceId) {
          setAudioInputDevice(deviceId);
          setInputDevice(device);
          dispatch(
            updateUserPreferences({
              ...userPreferences,
              defaultMicrophone: deviceId,
            })
          );
        }
      }
    },
    [
      dispatch,
      inputDevices,
      setAudioInputDevice,
      setInputDevice,
      userPreferences,
      inputDevice,
    ]
  );

  const handleOutputChange = useCallback(
    (deviceId: string) => {
      const device = outputDevices.find((d) => d.deviceId === deviceId);
      if (device) {
        // Only update if the device is different from current
        if (outputDevice?.deviceId !== device.deviceId) {
          setAudioOutputDevice(deviceId);
          setOutputDevice(device);
          dispatch(
            updateUserPreferences({
              ...userPreferences,
              defaultAudioDevice: deviceId,
            })
          );
        }
      }
    },
    [
      dispatch,
      outputDevices,
      setAudioOutputDevice,
      setOutputDevice,
      userPreferences,
      outputDevice,
    ]
  );

  const renderAudioForm = (isDisabled = false) => (
    <div
      className="settings-audio-form"
      style={{ opacity: isDisabled ? 0.5 : 1 }}
    >
      <div className="body-text mb-8">
        <div className="text-white-90 mb-4">Speakers</div>
        <div className="text-gs-700 mb-4">
          Select which speaker should be used with <br />
          Bridge when calling using a Bridge phone.
        </div>
        <div className="br-form-control custom-select" ref={audioDropdownRef}>
          <div
            className="selected-option"
            onClick={isDisabled ? undefined : toggleAudioDropdown}
            style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
          >
            <div className="selected-option--inside">
              {isDisabled
                ? "Permission required"
                : outputDevice?.label || "Select a speaker"}
              <span
                className={`dropdown-arrow ${
                  isAudioDropdownOpen ? "open" : ""
                }`}
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ fontSize: "12px" }}
                />
              </span>
            </div>
          </div>
          {!isDisabled && isAudioDropdownOpen && (
            <div className="options">
              {outputDevices?.map((device) => (
                <div
                  key={device.deviceId}
                  className={`option ${
                    device.deviceId === outputDevice?.deviceId ? "selected" : ""
                  }`}
                  onClick={() => {
                    if (device.deviceId) {
                      handleOutputChange(device.deviceId);
                      setIsAudioDropdownOpen(false);
                    }
                  }}
                >
                  {device.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="body-text mb-8">
        <div className="text-white-90 mb-4">Microphone</div>
        <div className="text-gs-700 mb-4">
          Select which microphone should be used <br />
          with Bridge when calling using a Bridge phone.
        </div>
        <div className="br-form-control custom-select" ref={micDropdownRef}>
          <div
            className="selected-option"
            onClick={isDisabled ? undefined : toggleMicDropdown}
            style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
          >
            <div className="selected-option--inside">
              {isDisabled
                ? "Permission required"
                : inputDevice?.label || "Select a microphone"}
              <span
                className={`dropdown-arrow ${isMicDropdownOpen ? "open" : ""}`}
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ fontSize: "12px" }}
                />
              </span>
            </div>
          </div>
          {!isDisabled && isMicDropdownOpen && (
            <div className="options">
              {inputDevices?.map((device) => (
                <div
                  key={device.deviceId}
                  className={`option ${
                    device.deviceId === inputDevice?.deviceId ? "selected" : ""
                  }`}
                  onClick={() => {
                    if (device.deviceId) {
                      handleInputChange(device.deviceId);
                      setIsMicDropdownOpen(false);
                    }
                  }}
                >
                  {device.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        audioDropdownRef.current &&
        !audioDropdownRef.current.contains(event.target as Node)
      ) {
        setIsAudioDropdownOpen(false);
      }
      if (
        micDropdownRef.current &&
        !micDropdownRef.current.contains(event.target as Node)
      ) {
        setIsMicDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updateDeviceSelection = useCallback(() => {
    if (permissionStatus !== "granted") return;

    // Add check to prevent unnecessary device updates
    const shouldUpdateDevices =
      (!inputDevice && inputDevices.length === 0) ||
      (!outputDevice && outputDevices.length === 0);

    if (shouldUpdateDevices) {
      getDevices().then(() => {
        // Only update if there's no current device selected
        if (!inputDevice && inputDevices.length > 0) {
          const defaultInput =
            inputDevices.find((d) => d.deviceId === "default") ||
            inputDevices[0];
          if (defaultInput?.deviceId) {
            handleInputChange(defaultInput.deviceId);
          }
        }

        if (!outputDevice && outputDevices.length > 0) {
          const defaultOutput =
            outputDevices.find((d) => d.deviceId === "default") ||
            outputDevices[0];
          if (defaultOutput?.deviceId) {
            handleOutputChange(defaultOutput.deviceId);
          }
        }
      });
    }
  }, [
    permissionStatus,
    inputDevice,
    inputDevices,
    outputDevice,
    outputDevices,
    getDevices,
    handleInputChange,
    handleOutputChange,
  ]);

  useEffect(() => {
    if (permissionStatus === "granted") {
      updateDeviceSelection();
    }
  }, [permissionStatus, updateDeviceSelection]);

  const warningMessage =
    "Bridge currently does not have permission from the browser to access the audio devices needed for the 'Calling with Bridge' functionality. Refer to the documentation to learn how to grant Bridge the required access.";

  const warningMessageDesktop =
    "Bridge currently does not have permission to access the audio devices needed for the 'Calling with Bridge' functionality. Refer to the documentation to learn how to grant Bridge the required access.";

  if (permissionStatus === "denied") {
    return (
      <>
        <Warning
          title="Requires permission"
          message={isElectron() ? warningMessageDesktop : warningMessage}
        />
        {renderAudioForm(true)}
      </>
    );
  }

  return renderAudioForm(false);
};

export default SettingsAudioForm;
