import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Call, User, Side, CallPointState } from "compass.js";
import { shouldShowCallForUser, getUserSide } from "src/utils/call";
import ringing from "src/assets/sounds/ringing.mp3";
import { PhoneActionType, phoneAction } from "src/store/actions";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { canAuthenticatedUserUseFeature } from "src/utils/user";
import { isElectron } from "src/utils";
import { IRootState } from "../../../store/reducers";
import { createSelector } from "reselect";
import { DialerWebrtcContext } from "src/providers/DialerWebrtcContext";
import { CallStatus } from "src/utils/dialerWebrtc";
import { PermissionStatus } from "src/providers/DialerWebrtcContext";

interface ExtendedNotificationOptions extends NotificationOptions {
  actions?: Array<{ action: string; title: string }>;
}

const selectCalls = (state: IRootState) => state.calls;
const selectAuth = (state: IRootState) => state.auth;
const selectWindow = (state: IRootState) => state.window;

const appDataSelector = createSelector(
  [selectCalls, selectAuth, selectWindow],
  (calls, auth, window) => {
    const ringingCalls = calls.items.filter((call) => {
      const selfSide = getUserSide(call, auth.user as User);
      return (
        shouldShowCallForUser(call, auth.user as User) &&
        selfSide === Side.destination &&
        call.destination.state === CallPointState.ringing &&
        !calls.callsMetadata[call.id].isEnded
      );
    });
    const phoneCapabilities = auth.phone ? auth.phone.capabilities : [];
    const callsMetadata = calls.callsMetadata;
    const online = window.online;
    const onboardingMode = auth.onboardingMode;
    const phone = auth.phone;

    return {
      ringingCalls,
      phoneCapabilities,
      callsMetadata,
      online,
      onboardingMode,
      phone,
    };
  }
);

const CallNotifications: React.FC = () => {
  const {
    ringingCalls,
    phoneCapabilities,
    callsMetadata,
    online,
    onboardingMode,
    phone,
  } = useSelector(appDataSelector);

  const dispatch = useDispatch();

  const [ignore, setIgnore] = useState(true);
  const [swRegistration, setSwRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >(undefined);
  const [hasActiveWebRTCCall, setHasActiveWebRTCCall] = useState(false);
  const [isWebRTCCallAnswered, setIsWebRTCCallAnswered] = useState(false);

  const shownNotifications = useRef<{
    [key: string]: Notification | undefined;
  }>({});
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const swApi = navigator.serviceWorker;

  const { dialer, currentOutputDeviceId, permissionStatus } =
    useContext(DialerWebrtcContext);

  const onSWNotificationClick = React.useCallback(
    (e: MessageEvent) => {
      const onPhoneActionHandler = (
        callId: Call["id"],
        action: PhoneActionType
      ) => dispatch<any>(phoneAction(callId, action));
      if (e.data.type !== "notification-clicked") {
        return;
      }
      focusWindow();
      if (!e.data.action) {
        return;
      }
      const call = ringingCalls.find((item) => item.id === e.data.notification);
      if (!call) {
        return;
      }
      switch (e.data.action) {
        case "answer":
          onPhoneActionHandler(call.id, PhoneActionType.answerCall);
          break;
        case "decline":
          onPhoneActionHandler(call.id, PhoneActionType.hangupCall);
          break;
      }
    },
    [dispatch, ringingCalls]
  );

  const onNotificationClick = React.useCallback((e: any) => {
    e.preventDefault();
    focusWindow();
    e.target.close();
  }, []);

  const updateAudio = useCallback(() => {
    if (!audioRef.current) return;

    const shouldPlayRinging =
      (ringingCalls.length > 0 && online) ||
      (phone?.isBridgePhone && hasActiveWebRTCCall && !isWebRTCCallAnswered);

    if (shouldPlayRinging) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      }
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [ringingCalls, online, phone, hasActiveWebRTCCall, isWebRTCCallAnswered]);

  const clearExpiredNotifications = React.useCallback(() => {
    const callsIds = ringingCalls.map((item) => item.id);
    Object.keys(shownNotifications.current).forEach((id) => {
      if (!callsIds.includes(id)) {
        const notification = shownNotifications.current[id];
        if (notification) {
          notification.close();
          removeNotification(id);
        }
      }
    });
  }, [ringingCalls]);

  const showNotifications = React.useCallback(() => {
    if (ignore || onboardingMode) {
      return;
    }
    clearExpiredNotifications();
    ringingCalls.forEach((call) => {
      if (call.id in shownNotifications.current) {
        return;
      }
      const title = callsMetadata[call.id].lastConnectedTitle;
      const queue = callsMetadata[call.id].queue;
      let body: string;
      if (queue) {
        body = `From Bridge, queue ${queue.name}`;
      } else {
        body = "From Bridge, direct call";
      }
      const options: ExtendedNotificationOptions = {
        body,
        requireInteraction: true,
        lang: "en",
        dir: "ltr",
        tag: call.id,
      };
      if (swRegistration && "showNotification" in swRegistration) {
        options.actions = [{ action: "decline", title: "Decline" }];
        if (
          phoneCapabilities.includes(PhoneCapability.answer) &&
          canAuthenticatedUserUseFeature(UserFeature.callcontrol)
        ) {
          options.actions.unshift({ action: "answer", title: "Answer" });
        }
        const registration = swRegistration;
        removeNotification(call.id);
        registration.showNotification(title, options).then(() => {
          registration
            .getNotifications({ tag: call.id })
            .then((notifications) => {
              if (!notifications.length) {
                return;
              }
              const notification = notifications[0];
              shownNotifications.current[call.id] = notification;
              notification.addEventListener("click", onNotificationClick);
            });
        });
      } else {
        const notification = new Notification(title, options);
        notification.addEventListener("click", onNotificationClick);
        shownNotifications.current[call.id] = notification;
      }
    });
  }, [
    clearExpiredNotifications,
    ignore,
    onboardingMode,
    onNotificationClick,
    callsMetadata,
    phoneCapabilities,
    ringingCalls,
    swRegistration,
  ]);

  const focusWindow = () => {
    if (isElectron()) {
      window.remote.getCurrentWindow().show();
    } else {
      window.focus();
    }
  };

  const onSWRegister = (swRegistration: ServiceWorkerRegistration) => {
    setSwRegistration(swRegistration);
  };

  const checkNotificationsAccess = () => {
    if (!("Notification" in window) || Notification.permission === "denied") {
      setIgnore(true);
    } else if (Notification.permission === "granted") {
      setIgnore(false);
    } else {
      Notification.requestPermission((e) => {
        setIgnore(e === "denied");
      });
    }
  };

  const removeNotification = (callId: string) => {
    const notification = shownNotifications.current[callId];
    if (!notification) {
      return;
    }
    delete shownNotifications.current[callId];
    notification.close();
  };

  const closeAllNotifications = React.useCallback(() => {
    Object.values(shownNotifications.current).forEach((item) => {
      if (item) {
        removeNotification(item.tag);
      }
    });
  }, []);

  useEffect(() => {
    checkNotificationsAccess();
    window.addEventListener("beforeunload", closeAllNotifications);
    window.addEventListener("offline", closeAllNotifications);

    if (swApi) {
      swApi.addEventListener("message", onSWNotificationClick);
      swApi.register("./notifications-service-worker.js").then(onSWRegister);
    }
    return () => {
      window.removeEventListener("beforeunload", closeAllNotifications);
      window.removeEventListener("offline", closeAllNotifications);
      if (swApi) {
        swApi.removeEventListener("message", onSWNotificationClick);
      }
    };
  }, [closeAllNotifications, onSWNotificationClick, swApi]);

  useEffect(() => {
    updateAudio();
    showNotifications();
    if (phone?.isBridgePhone && dialer) {
      dialer.onStatusChange = (status, identity, displayName) => {
        if (status === CallStatus.Received) {
          setHasActiveWebRTCCall(true);
          setIsWebRTCCallAnswered(false);
        } else if (status === CallStatus.Active) {
          setIsWebRTCCallAnswered(true);
        } else if (status === CallStatus.Ended) {
          setHasActiveWebRTCCall(false);
          setIsWebRTCCallAnswered(false);
        }
      };
    }
  }, [phone, dialer, updateAudio, showNotifications]);

  useEffect(() => {
    const setAudioDevice = async () => {
      if (
        phone?.isBridgePhone &&
        audioRef.current &&
        currentOutputDeviceId &&
        permissionStatus === PermissionStatus.granted
      ) {
        try {
          // Validate if setSinkId is supported
          if (!("setSinkId" in audioRef.current)) {
            console.warn("setSinkId not supported");
            return;
          }

          // Validate if device exists
          const devices = await navigator.mediaDevices.enumerateDevices();
          const deviceExists = devices.some(
            (device) =>
              device.kind === "audiooutput" &&
              device.deviceId === currentOutputDeviceId
          );

          // Use default or existing device
          const deviceId = deviceExists ? currentOutputDeviceId : "default";

          await (audioRef.current as any).setSinkId(deviceId);
        } catch (error) {
          console.warn("Error setting audio output device:", error);
          // Fallback to default device
          try {
            await (audioRef.current as any).setSinkId("default");
          } catch (fallbackError) {
            console.error("Failed to set default audio device:", fallbackError);
          }
        }
      }
    };

    setAudioDevice();
  }, [currentOutputDeviceId, phone?.isBridgePhone, permissionStatus]);
  return (
    <audio ref={audioRef} preload="auto" autoPlay={false} loop={true}>
      <source src={ringing} type="audio/mpeg" />
    </audio>
  );
};

export default CallNotifications;
