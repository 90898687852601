import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/pro-solid-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";

import { usePrevious } from "src/utils/hooks";
import Radio from "../Radio/Radio";

import "./Table.scss";
interface TableCell {
  value: string | ReactElement;
  rawValue?: string | number;
}
const normalizeCellValue = (cell: TableCell): string => {
  if (cell.rawValue !== undefined) {
    return String(cell.rawValue);
  }
  return React.isValidElement(cell.value) ? "" : String(cell.value);
};
const TablePagination: React.FC<{
  pagesCount: number;
  activePage: number;
  onPageChange: (page: number) => void;
}> = ({ pagesCount, activePage, onPageChange }) => {
  const pagesBeforeActive = Array.from(
    { length: Math.min(3, activePage) },
    (_, i) => activePage - i
  ).reverse();

  const pagesAfterActive = Array.from(
    { length: Math.min(3, pagesCount - activePage) },
    (_, i) => activePage + i
  );

  const pages =
    pagesCount <= 5
      ? Array.from({ length: pagesCount }, (_, i) => i)
      : Array.from(
          new Set([
            0,
            ...pagesBeforeActive,
            ...pagesAfterActive,
            pagesCount - 1,
          ])
        );
  const showLeftDots = pagesCount > 5 && activePage > 3;
  const showRightDots = pagesCount > 5 && activePage < pagesCount - 4;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    event.preventDefault();
    onPageChange(page);
  };

  return (
    <div className="table-pagination">
      <button
        className={`pagination-button ${activePage === 0 ? "disabled" : ""}`}
        disabled={activePage === 0}
        onClick={(e) => handleClick(e, activePage - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <span className="button-text">Previous</span>
      </button>
      {pages.map((page, idx) => (
        <Fragment key={page}>
          <button
            onClick={(e) => handleClick(e, page)}
            className={`pagination-page-button ${
              page === activePage ? "active" : ""
            }`}
          >
            {page + 1}
          </button>
          {showLeftDots && idx === 0 && (
            <span className="pagination-dots">...</span>
          )}
          {showRightDots && idx === pages.length - 2 && (
            <span className="pagination-dots">...</span>
          )}
        </Fragment>
      ))}
      <button
        className={`pagination-button ${
          activePage === pagesCount - 1 || !pagesCount ? "disabled" : ""
        }`}
        disabled={activePage === pagesCount - 1 || !pagesCount}
        onClick={(e) => handleClick(e, activePage + 1)}
      >
        <span className="button-text">Next</span>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

const Table: React.FC<{
  columns: {
    title?: string;
    type: "number" | "string" | "radio";
    extraCssClass?: string;
    searchable?: boolean;
  }[];
  data: TableCell[][];
  searchQuery?: string;
  pagination?: { rowsPerPage: number };
  onRowClick?: (idx: number) => void;
  animateRow?: number;
  selectedValue?: string | number;
  onRowSelect?: (row: number) => void;
  sort?: { column: number; sort: "asc" | "desc" } | undefined;
  onColumnHeaderClick?: (column: number, sort: "asc" | "desc" | "none") => void;
  onRadioSelect?: (value: string) => void;
  pinnedRowsFilter?: (row: { cols: TableCell[]; idx: number }) => boolean;
}> = ({
  columns,
  data,
  searchQuery,
  pagination = { rowsPerPage: 25 },
  onRowClick,
  animateRow,
  selectedValue,
  onRowSelect,
  sort,
  onColumnHeaderClick,
  onRadioSelect,
  pinnedRowsFilter,
}) => {
  const [activePage, setActivePage] = useState(0);
  const previousSearchQuery = usePrevious(searchQuery);

  useEffect(() => {
    if (
      searchQuery &&
      searchQuery !== previousSearchQuery &&
      activePage !== 0
    ) {
      setActivePage(0);
    }
  }, [searchQuery, previousSearchQuery, activePage]);

  const visibleData = useMemo(() => {
    let result = data.map((cols, idx) => ({ cols, idx }));

    result = searchQuery
      ? result.filter((row) =>
          row.cols.find((item, idx) => {
            if (!columns[idx].searchable) return false;
            const normalizedValue = normalizeCellValue(item);
            return normalizedValue
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          })
        )
      : result;

    const pinnedRows = pinnedRowsFilter ? result.filter(pinnedRowsFilter) : [];
    const regularRows = pinnedRowsFilter
      ? result.filter((row) => !pinnedRowsFilter(row))
      : result;

    if (sort) {
      regularRows.sort((a, b) => {
        const aCell = a.cols[sort.column];
        const bCell = b.cols[sort.column];

        const aValue = normalizeCellValue(aCell).toLowerCase();
        const bValue = normalizeCellValue(bCell).toLowerCase();

        if (aValue < bValue) return sort.sort === "asc" ? -1 : 1;
        if (aValue > bValue) return sort.sort === "asc" ? 1 : -1;
        return 0;
      });
    }

    return [...pinnedRows, ...regularRows];
  }, [data, searchQuery, columns, sort, pinnedRowsFilter]);
  const pagesCount = Math.ceil(visibleData.length / pagination.rowsPerPage);
  const pageData = visibleData.slice(
    activePage * pagination.rowsPerPage,
    (activePage + 1) * pagination.rowsPerPage
  );

  const handleColumnHeaderClick = (column: number) => {
    if (!onColumnHeaderClick) {
      return;
    }
    if (sort?.column === column) {
      if (sort.sort === "asc") {
        onColumnHeaderClick(column, "desc");
      } else {
        onColumnHeaderClick(column, "none");
      }
    } else {
      onColumnHeaderClick(column, "asc");
    }
  };

  return (
    <div className="table-container">
      <table className="table rounded-corners">
        <thead>
          <tr className="table-header">
            {columns.map(({ title, extraCssClass, type }, idx) => (
              <th
                key={idx}
                onClick={() => handleColumnHeaderClick(idx)}
                className={`table-header-cell ${extraCssClass || ""}`}
              >
                <div className="header-content">
                  <span className="header-title">{title}</span>
                  {type !== "radio" && (
                    <span className="sort-icons">
                      <FontAwesomeIcon
                        icon={faSortUp}
                        className={`sort-icon ${
                          sort?.column === idx && sort?.sort === "desc"
                            ? "active"
                            : ""
                        }`}
                      />
                      <FontAwesomeIcon
                        icon={faSortDown}
                        className={`sort-icon ${
                          sort?.column === idx && sort?.sort === "asc"
                            ? "active"
                            : ""
                        }`}
                      />
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pageData.map((row) => (
            <tr
              key={`row-${row.idx}`}
              className={`table-row ${
                selectedValue === row.cols[0].rawValue ? "selected" : ""
              }`}
              onClick={() => onRowSelect && onRowSelect(row.idx)}
            >
              {row.cols.map(({ value, rawValue }, colIdx) => (
                <td
                  key={`col-${colIdx}-${row.idx}`}
                  className="table-cell"
                  onClick={onRowClick?.bind(null, row.idx)}
                >
                  {columns[colIdx] && columns[colIdx].type === "radio" ? (
                    <Radio
                      options={[
                        {
                          value: rawValue?.toString() || row.idx.toString(),
                          name: "",
                        },
                      ]}
                      value={
                        selectedValue === rawValue
                          ? rawValue?.toString()
                          : undefined
                      }
                      onSelect={(value) => {
                        onRowSelect && onRowSelect(row.idx);
                        onRadioSelect && onRadioSelect(value);
                      }}
                      className="table-radio"
                    />
                  ) : (
                    value
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {pagesCount > 1 && (
        <div className="pagination-container">
          <TablePagination
            activePage={activePage}
            onPageChange={setActivePage}
            pagesCount={pagesCount}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
