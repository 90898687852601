import { Connection } from "compass.js";
import { handleError, wrapApiError } from "./errorHandler";
import { ICompassPhone } from "src/store/reducers/auth";
import { compassObjUrlToID, sortBy, sortIgnoreCaseComparator } from "src/utils";
import stable from "stable";
import { UserFeature } from "src/store/reducers/auth";
import { canAuthenticatedUserUseFeature } from "./user";
import { fetchBridgePhones } from "./user";

export interface PhoneData {
  id: number;
  name: string;
  type: string;
  userId?: number;
  userName?: string;
  isBridgePhone?: boolean;
}

interface SipAccount {
  secret: string;
  domain: string;
  username: string;
}

export const fetchSipAccount = async (
  phoneId: number,
  connection: Connection,
  setupDialer?: (config: {
    id: string;
    password: string;
    baseDomain: string;
  }) => void
): Promise<void> => {
  try {
    const response = await connection.rest.get(`phone/${phoneId}/sipAccounts`);
    const sipAccount = response[0] as SipAccount;
    const baseDomain = sipAccount.domain.substring(
      sipAccount.domain.indexOf(".") + 1
    );

    setupDialer?.({
      id: phoneId.toString(),
      password: sipAccount.secret,
      baseDomain: baseDomain,
    });
  } catch (error) {
    handleError(error);
  }
};

export const fetchCompanyPhones = async (
  connection: Connection,
  companyId: number,
  userId: string | undefined,
  compassContacts: any,
  recentPhones: number[]
): Promise<PhoneData[]> => {
  const rest = connection.rest;
  let companyPhones: PhoneData[] = [];
  let bridgePhoneData: PhoneData[] = [];

  try {
    const phones = await wrapApiError<ICompassPhone[]>(
      rest.get(`${rest.getUrlForObject("company", companyId)}/phones`)
    );
    const models = await wrapApiError<ICompassPhone[]>(
      rest.get(`phoneModel/list`)
    );
    const phoneDetailsPromises = phones.map(async (phone: ICompassPhone) => {
      const phoneModelUrl = phone.model;

      const phoneModelResponse =
        models.find((model) => model.self === phoneModelUrl)?.name || "Unknown";
      return { ...phone, phoneModelDetails: phoneModelResponse };
    });

    if (canAuthenticatedUserUseFeature(UserFeature.callingwbridge) && userId) {
      try {
        bridgePhoneData = await fetchBridgePhones(Number(userId), connection);
      } catch (error) {
        handleError(error);
      }
    }

    const phonesWithDetails = await Promise.all(phoneDetailsPromises);

    companyPhones = sortBy(
      phonesWithDetails.map((phone) => {
        const phoneId = phone.resourceId;
        const loggedInUser = phone.loggedInUser
          ? compassContacts[compassObjUrlToID(phone.loggedInUser)]
          : undefined;
        const phoneData: PhoneData = {
          id: phoneId,
          name: phone.name,
          type: phone.phoneModelDetails,
          isBridgePhone: false,
        };
        if (loggedInUser) {
          phoneData.userName = loggedInUser.name;
          phoneData.userId = parseInt(loggedInUser.id, 10);
        }
        return phoneData;
      }),
      (item) => item.name,
      sortIgnoreCaseComparator
    );

    companyPhones = stable(companyPhones, (a, b) => {
      if (recentPhones.includes(a.id) && !recentPhones.includes(b.id)) {
        return -1;
      } else if (!recentPhones.includes(a.id) && recentPhones.includes(b.id)) {
        return 1;
      }
      if (recentPhones.indexOf(a.id) < recentPhones.indexOf(b.id)) {
        return -1;
      } else if (recentPhones.indexOf(a.id) > recentPhones.indexOf(b.id)) {
        return 1;
      }
      return 0;
    });

    companyPhones = [...bridgePhoneData, ...companyPhones];
    const loggedInPhoneIdx = companyPhones.findIndex(
      (item) => item.userId === Number(userId)
    );
    if (loggedInPhoneIdx > 0) {
      const loggedInPhone = companyPhones[loggedInPhoneIdx];
      companyPhones.splice(loggedInPhoneIdx, 1);
      companyPhones.unshift(loggedInPhone);
    }

    return companyPhones;
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const fetchUserBridgePhones = async (
  userId: number,
  connection: Connection
): Promise<any[]> => {
  const rest = connection.rest;
  return await wrapApiError<any[]>(
    rest.get(`${rest.getUrlForObject("user", userId)}/phones`)
  );
};
