import * as React from "react";
import "./Warning.scss";
import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";

interface WarningProps {
  title: string;
  message: string;
}
export const Warning: React.FC<PropsWithChildren<WarningProps>> = (props) => {
  const { title, message } = props;
  return (
    <div className="wargning-box">
      <>
        <div className="wargning-box-inside">
          <div className="wargning-box-icon">
            <div className="wargning-box-icon-inside">
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </div>
          </div>
          <div className="wargning-box-text">
            <h4 className="wargning-box-title">{title}</h4>
            <p className="wargning-box-message">{message}</p>
          </div>
        </div>
      </>
    </div>
  );
};
