import { ErrorType } from "./../../utils/errorHandler";
import { ICompassUserStatus } from "./../reducers/auth";
import { compassDebouncePipe } from "./../../utils/compassDataMiddleware";
import { matchPhoneNumbers, parseDestinationNumber } from "src/utils/index";
import { CallMetadata } from "src/utils/CallMetadata";
import {
  getCallPointNumber,
  shouldShowCallForUser,
  callPointMatchesNumber,
  getCallQueue,
  getCallPointTitle,
} from "src/utils/call";
import { IRootState } from "src/store/reducers/index";
import {
  Connection,
  User,
  Call,
  OtherSide,
  Side,
  ListenInCallPoint,
  UserCallPoint,
  CallPoint,
  CallPointState,
  CallPointType,
} from "compass.js";
import * as actionTypes from "./actionTypes";
import { AnyAction } from "redux";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { dispatchWithProgress } from "src/utils/redux";
import { store } from "..";
import {
  containsCallById,
  getUserSide,
  shouldAutoAnswerCall,
} from "src/utils/call";
import clone from "clone";
import { wrapApiError, checkPhone } from "src/utils/errorHandler";
import { compassDataMiddleware } from "src/utils/compassDataMiddleware";
import { notificationShow } from "./notifications";
import { navigationUpdateParams } from "./navigation";
import { Dialer as DialerWebrtcType } from "src/utils/dialerWebrtc";
const CALL_DISAPPEAR_TIMEOUT = 3000;
const CALL_HIGHLIGHT_DURATION = 300;

const callsDispatchWithProgress = <PropType>(
  action: Promise<any>
): Promise<PropType> => {
  return dispatchWithProgress<PropType>(
    action,
    actionTypes.CALLS_ACTION_STARTED,
    actionTypes.CALLS_ACTION_COMPLETED
  );
};

const updateCalls = (
  calls: Call[],
  callsMetadata: { [key: string]: CallMetadata }
): {
  type: string;
  payload: {
    calls: Call[];
    callsMetadata: { [key: string]: CallMetadata };
  };
} => {
  return {
    type: actionTypes.CALLS_UPDATE,
    payload: {
      calls: clone(calls, undefined, 2),
      callsMetadata,
    },
  };
};

const updateAllCalls = (
  calls: Call[],
  callsMetadata: { [key: string]: CallMetadata }
): {
  type: string;
  payload: {
    calls: Call[];
    callsMetadata: { [key: string]: CallMetadata };
  };
} => {
  return {
    type: actionTypes.CALLS_UPDATE_ALL,
    payload: {
      calls,
      callsMetadata,
    },
  };
};

const updateEndedCalls = (calls: Call[]): { type: string; payload: Call[] } => {
  return {
    type: actionTypes.CALLS_ADD_ENDED,
    payload: calls,
  };
};

const removeEndedCalls = (calls: Call[]): { type: string; payload: Call[] } => {
  return {
    type: actionTypes.CALLS_REMOVE_ENDED,
    payload: calls,
  };
};

const answerDiallerCallStart = (call: Call) => {
  return {
    type: actionTypes.CALLS_ANSWER_DIALLER_CALL_START,
    payload: call.id,
  };
};

const answerDiallerCallFinish = (call: Call) => {
  return {
    type: actionTypes.CALLS_ANSWER_DIALLER_CALL_FINISH,
    payload: call.id,
  };
};

const addHighlightedCall = (
  callId: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.CALLS_ADD_HIGHLIGHTED_CALL,
    payload: callId,
  };
};

const removeHighlightedCall = (
  callId: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.CALLS_REMOVE_HIGHLIGHTED_CALL,
    payload: callId,
  };
};

export enum PhoneActionType {
  hangupCall = "hangupCall",
  holdCall = "holdCall",
  unholdCall = "unholdCall",
  answerCall = "answerCall",
  transferCall = "transferCall",
}

export const highlightCall = (
  callId: string
): ThunkAction<Promise<void>, IRootState, void, AnyAction> => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(addHighlightedCall(callId));
      setTimeout(() => {
        dispatch(removeHighlightedCall(callId));
        resolve();
      }, CALL_HIGHLIGHT_DURATION);
    });
  };
};

export const setAsDesiredTransferCall = (
  callId: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.CALLS_START_TRANSFERRING,
    payload: callId,
  };
};

export const resetDesiredTransferCall = (): { type: string } => {
  return {
    type: actionTypes.CALLS_DISMISS_TRANSFERRING,
  };
};

export const startAttendedTransfer = (
  destination: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return (dispatch, getState) => {
    dispatch(registerAttendedTransferDestination(destination));
    return callsDispatchWithProgress(
      new Promise<void>((resolve, reject) => {
        dispatch(dialNumber(destination)).then(() => {
          let timeout: NodeJS.Timer;
          const unsubscribe = store.subscribe(() => {
            const state = getState();
            // NOTE: transfer canceled
            if (
              !state.calls.desiredTransferCallId ||
              !state.calls.desiredAttendedTransferDestination
            ) {
              clearTimeout(timeout);
              unsubscribe();
              dispatch(cancelAttendedTransferDestination());
              return resolve();
            }
            const consultationCall = state.calls.items.find((call) => {
              if (
                !shouldShowCallForUser(call, getState().auth.user as User) ||
                call.id === state.calls.desiredTransferCallId
              ) {
                return false;
              }
              const callMetadata = getState().calls.callsMetadata[call.id];
              return (
                callMetadata &&
                matchPhoneNumbers(
                  callMetadata.lastConnectedNumber || "",
                  destination || ""
                )
              );
            });
            if (consultationCall) {
              unsubscribe();
              dispatch(cancelAttendedTransferDestination());
              dispatch(
                registerAttendedTransfer(
                  state.calls.desiredTransferCallId,
                  consultationCall.id
                )
              );
              clearTimeout(timeout);
              resolve();
            }
          });
          // NOTE: dialler call didn't appear
          // or wasn't answered
          timeout = setTimeout(() => {
            unsubscribe();
            dispatch(cancelAttendedTransferDestination());
            reject();
          }, 30000);
        }, reject);
      })
    );
  };
};

export const registerAttendedTransferDestination = (destination: string) => {
  return {
    type: actionTypes.CALLS_REGISTER_ATTENDED_TRANSFER_DESTINATION,
    payload: {
      destination,
    },
  };
};

export const cancelAttendedTransferDestination = () => {
  return {
    type: actionTypes.CALLS_CANCEL_ATTENDED_TRANSFER_DESTINATION,
  };
};

export const registerAttendedTransfer = (
  mainCallId: Call["id"],
  consultationCallId: Call["id"]
) => {
  return {
    type: actionTypes.CALLS_REGISTER_ATTENDED_TRANSFER,
    payload: {
      mainCallId,
      consultationCallId,
    },
  };
};

const detectEndedCalls = (
  oldSet: Call[],
  newSet: Call[],
  metadataMap: { [key: string]: CallMetadata }
): Call[] => {
  // NOTE: don't show "Call ended" during onboarding
  if (store.getState().auth.onboardingMode) {
    return [];
  }
  return oldSet.filter((item) => {
    const callMetadata = metadataMap[item.id];
    // NOTE: don't show "call ended" for queue call
    // (https://gitlab.iperitydev.com/compass/bridge/-/merge_requests/261 discussion)
    if (
      callMetadata &&
      callMetadata.queue &&
      item.parentCall &&
      !containsCallById(newSet, item.id)
    ) {
      return false;
    }
    return (
      !(callMetadata && callMetadata.isEnded) &&
      !containsCallById(newSet, item.id)
    );
  });
};

export const setupCalls = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
) => {
  const getListenedToCall = (call: Call) => {
    if (
      call.source instanceof ListenInCallPoint &&
      call.destination instanceof UserCallPoint &&
      call.destination.state === CallPointState.answered &&
      // NOTE: don't trigger listenIn on initial dialler call
      shouldShowCallForUser(call, (call.destination as UserCallPoint).getUser())
    ) {
      return (
        call.domain.calls[
          (call.source as ListenInCallPoint).listenedToCallId
        ] || null
      );
    }
    return null;
  };

  const updateCallMetadata = (
    call: Call,
    callMetadata: CallMetadata,
    callsMetadata: { [key: string]: CallMetadata },
    user?: User
  ) => {
    let userSide: Side | null = null;
    let otherCallPoint: CallPoint | null = null;

    let queue = getCallQueue(call);
    if (user) {
      userSide = getUserSide(call, user);
      if (userSide !== null) {
        otherCallPoint = call.getEndpoint(OtherSide(userSide));
      }
    }

    if (otherCallPoint) {
      const callPointNumber = getCallPointNumber(otherCallPoint);
      if (callPointNumber) {
        callMetadata.lastConnectedNumber = callPointNumber;
      }
      const callPointTitle = getCallPointTitle(otherCallPoint, "");
      if (callPointTitle) {
        callMetadata.lastConnectedTitle = callPointTitle;
      }
    }

    const destinationNumber = getCallPointNumber(call.destination);
    if (destinationNumber) {
      callMetadata.destinationLastConnectedNumber = destinationNumber;
    }
    const destinationTitle = getCallPointTitle(call.destination, "");
    if (destinationTitle) {
      callMetadata.destinationLastConnectedTitle = destinationTitle;
    }

    const sourceNumber = getCallPointNumber(call.source);
    if (sourceNumber) {
      callMetadata.sourceLastConnectedNumber = sourceNumber;
    }
    const sourceTitle = getCallPointTitle(call.source, "");
    if (sourceTitle) {
      callMetadata.sourceLastConnectedNumber = sourceTitle;
    }

    if (
      (otherCallPoint instanceof ListenInCallPoint ||
        (!otherCallPoint && call.source instanceof ListenInCallPoint)) &&
      !callMetadata.isEnded
    ) {
      const listeningToCall =
        call.domain.calls[
          ((otherCallPoint || call.source) as ListenInCallPoint)
            .listenedToCallId
        ];
      if (listeningToCall) {
        callMetadata.listeningToCall = listeningToCall;
        const listeningToCallMetadata =
          store.getState().calls.allCallsMetadata[listeningToCall.id];
        if (
          !queue &&
          listeningToCallMetadata &&
          listeningToCallMetadata.queue
        ) {
          queue = listeningToCallMetadata.queue;
        }
      }
    }

    if (queue) {
      callMetadata.queue = queue;
      if (call.parentCall && !callsMetadata[call.parentCall.id]) {
        callsMetadata[call.parentCall.id] = new CallMetadata(call.parentCall);
        callsMetadata[call.parentCall.id].queue = queue;
      }
    }
  };

  const updateAllCallsFunc = (calls: Call[]) => {
    const callsMetadata: { [key: string]: CallMetadata } =
      store.getState().calls.allCallsMetadata;
    const listenedByMap: { [key: string]: Call[] } = {};
    Object.keys(callsMetadata).forEach((item) => {
      if (!calls.find((call) => call.id === item)) {
        delete callsMetadata[item];
      }
    });
    calls.forEach((call) => {
      let callMetadata = callsMetadata[call.id];
      if (!callMetadata) {
        callMetadata = callsMetadata[call.id] = new CallMetadata(call);
      } else {
        // NOTE: make sure the call object always updated
        callMetadata.call = call;
      }
      const listenedToCall = getListenedToCall(call);
      if (listenedToCall) {
        listenedByMap[listenedToCall.id] = [
          ...(listenedByMap[listenedToCall.id] || []),
          call,
        ];
      }
      updateCallMetadata(call, callMetadata, callsMetadata);
    });
    calls.forEach((call) => {
      callsMetadata[call.id].listenedByCalls = listenedByMap[call.id] || [];
    });
    dispatch(updateAllCalls(calls, callsMetadata));
  };

  const updateSelfCallsFunc = (activeCalls: Call[]) => {
    const callsState = store.getState().calls;
    const listenedByMap: { [key: string]: Call[] } = {};
    const endedCalls = detectEndedCalls(
      callsState.items,
      activeCalls,
      callsState.callsMetadata
    );
    const callsMetadata: { [key: string]: CallMetadata } =
      store.getState().calls.callsMetadata;

    if (endedCalls.length) {
      dispatch(updateEndedCalls(endedCalls));
      setTimeout(() => {
        dispatch(removeEndedCalls(endedCalls));
      }, CALL_DISAPPEAR_TIMEOUT);
    }

    const calls = [...activeCalls, ...store.getState().calls.endedItems];
    const allCompanyCalls = (store.getState().auth.connection as Connection)
      ?.model?.calls;

    endedCalls.forEach((endedCall) => {
      const callMetadata = callsMetadata[endedCall.id];
      if (!callMetadata) {
        return;
      }
      if (
        callMetadata.listeningToCall &&
        !allCompanyCalls[callMetadata.listeningToCall.id]
      ) {
        dispatch(
          notificationShow({
            message: "The call you were listening in on has ended.",
            level: "info",
            dismissable: true,
            autoDismiss: 5000,
          })
        );
      }
      callMetadata.isEnded = true;
    });
    if (allCompanyCalls) {
      Object.values(allCompanyCalls).forEach((call) => {
        const listenedToCall = getListenedToCall(call);
        if (listenedToCall) {
          listenedByMap[listenedToCall.id] = [
            ...(listenedByMap[listenedToCall.id] || []),
            call,
          ];
        }
      });
    }

    calls.forEach((call) => {
      let callMetadata = callsMetadata[call.id];
      if (!callMetadata) {
        callMetadata = callsMetadata[call.id] = new CallMetadata(call);
      } else {
        // NOTE: make sure the call object always updated
        callMetadata.call = call;
      }

      updateCallMetadata(
        call,
        callMetadata,
        callsMetadata,
        store.getState().auth.user
      );

      if (!callMetadata.tracked) {
        callMetadata.track();
      }
      // NOTE: auto answer dialler calls
      if (
        shouldAutoAnswerCall(call) &&
        !store
          .getState()
          .calls.answeredDiallerCallsInProgress.find((item) => call.id === item)
      ) {
        dispatch(answerDiallerCallStart(call));
        dispatch(phoneAction(call.id, PhoneActionType.answerCall)).then(
          () => {
            dispatch(answerDiallerCallFinish(call));
          },
          (error) => {
            console.error(error);
            dispatch(answerDiallerCallFinish(call));
          }
        );
      }
    });
    // NOTE: check if call for dtmf still exists
    const navigationParams = store.getState().navigation.params;
    if (
      navigationParams &&
      navigationParams.callIdForDtmf &&
      (!containsCallById(activeCalls, navigationParams.callIdForDtmf) ||
        callsMetadata[navigationParams.callIdForDtmf].isEnded)
    ) {
      dispatch(
        navigationUpdateParams({
          callIdForDtmf: undefined,
          dialerActive: false,
        })
      );
    }
    calls.forEach((call) => {
      callsMetadata[call.id].listenedByCalls = listenedByMap[call.id] || [];
    });
    dispatch(updateCalls(calls, callsMetadata));
  };

  compassDataMiddleware.userCalls$
    .pipe(compassDebouncePipe())
    .subscribe(updateSelfCallsFunc);

  compassDataMiddleware.calls$
    .pipe(compassDebouncePipe())
    .subscribe((callsMap) => updateAllCallsFunc(Object.values(callsMap)));
};

export const redirectCall = (
  callId: string,
  destination: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    const url: string = connection.rest.getUrlForObject(
      "user",
      parseInt(user.id, 10)
    );
    return await callsDispatchWithProgress<any>(
      wrapApiError(
        connection.rest.post(`${url}/redirectCall`, {
          callId,
          destination: parseDestinationNumber(destination),
        })
      )
    );
  };
};

export const mergeCalls = (
  callId1: string,
  callId2: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    const url: string = connection.rest.getUrlForObject(
      "user",
      parseInt(user.id, 10)
    );
    return await callsDispatchWithProgress<any>(
      wrapApiError(
        connection.rest.post(`${url}/transferCall`, {
          callId1,
          callId2,
        })
      )
    );
  };
};

export const pickupQueueCall = (
  queueId: string,
  callId: string
): ThunkAction<Promise<void>, IRootState, void, AnyAction> => {
  return async (dispatch, getState) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    const url: string = connection.rest.getUrlForObject(
      "user",
      parseInt(user.id, 10)
    );
    return await callsDispatchWithProgress<any>(
      wrapApiError(
        connection.rest.post(`${url}/pickupQueueCall`, {
          queue: connection.rest.getUrlForObject(
            "queue",
            parseInt(queueId, 10)
          ),
          callId,
        })
      )
    );
  };
};

export const phoneAction = (
  callId: Call["id"],
  action: PhoneActionType
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    let url: string;
    switch (action) {
      case PhoneActionType.hangupCall:
      case PhoneActionType.transferCall:
        url = (getState().auth.connection as Connection).rest.getUrlForObject(
          "user",
          parseInt(user.id, 10)
        );
        break;
      default:
        // NOTE: we're sure that phone exists as checkPhone() didn't return error
        url = (getState().auth.userStatus as ICompassUserStatus)
          .phone as string;
        break;
    }
    let params: any;
    if (action === PhoneActionType.transferCall) {
      params = {
        callId1: callId,
        callId2: getState().calls.desiredTransferCallId,
      };
    } else {
      params = {
        callId,
      };
    }
    return await callsDispatchWithProgress<any>(
      wrapApiError(connection.rest.post(`${url}/${action}`, params))
    );
  };
};

export const dialNumber = (
  destination: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    if (String(destination).length > 5) {
      const identity = await callsDispatchWithProgress<any>(
        wrapApiError(connection.rest.getMyFirstIdentity())
      );
      if (!identity.cli) {
        // eslint-disable-next-line no-throw-literal
        throw {
          type: ErrorType.noOutboundNumber,
        };
      }
    }
    const calls = getState().calls.items;
    const parsedDestination = parseDestinationNumber(destination);
    const existingCall = calls
      .filter((item) => getUserSide(item, user) !== null)
      .filter((item) => shouldShowCallForUser(item, user))
      .filter(
        (item) =>
          callPointMatchesNumber(
            item.getEndpoint(Side.destination),
            parsedDestination
          ) ||
          callPointMatchesNumber(
            item.getEndpoint(Side.source),
            parsedDestination
          )
      )[0];
    if (existingCall) {
      return dispatch(highlightCall(existingCall.id));
    }
    return await callsDispatchWithProgress<any>(
      wrapApiError(
        connection.rest.post(`user/${user.id}/dialNumber`, {
          destination: parsedDestination,
        })
      )
    );
  };
};

export const dialNumberWebrtc = (
  destination: string,
  dialer?: DialerWebrtcType
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    if (String(destination).length > 5) {
      const identity = await callsDispatchWithProgress<any>(
        wrapApiError(connection.rest.getMyFirstIdentity())
      );
      if (!identity.cli) {
        // eslint-disable-next-line no-throw-literal
        throw {
          type: ErrorType.noOutboundNumber,
        };
      }
    }
    const calls = getState().calls.items;
    const parsedDestination = parseDestinationNumber(destination);
    const existingCall = calls
      .filter((item) => getUserSide(item, user) !== null)
      .filter((item) => shouldShowCallForUser(item, user))
      .filter(
        (item) =>
          callPointMatchesNumber(
            item.getEndpoint(Side.destination),
            parsedDestination
          ) ||
          callPointMatchesNumber(
            item.getEndpoint(Side.source),
            parsedDestination
          )
      )[0];
    if (existingCall) {
      return dispatch(highlightCall(existingCall.id));
    }
    dialer?.call(destination);
  };
};

export const listenIn = (
  callId: Call["id"]
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    return callsDispatchWithProgress<any>(
      new Promise<void>(async (resolve, reject) => {
        try {
          await wrapApiError(
            connection.rest.post(`user/${user.id}/listenIn`, {
              callId,
            })
          );
        } catch (error) {
          return reject(error);
        }
        // NOTE: wait until the listen in call appear
        const timeout = setTimeout(() => {
          unsubscribe();
          reject();
        }, 3000);
        const unsubscribe = store.subscribe(() => {
          const state = getState();
          const listenInCall = state.calls.items.find((item) => {
            return (
              item.source.type === CallPointType.listenIn &&
              (item.source as ListenInCallPoint).listenedToCallId === callId &&
              item.destination.state === CallPointState.answered
            );
          });
          if (listenInCall) {
            unsubscribe();
            clearInterval(timeout);
            resolve();
            return;
          }
        });
      })
    );
  };
};

export const sendDtmf = (
  callId: string,
  digits: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState) => {
    const connection = getState().auth.connection as Connection;
    const user = getState().auth.user as User;
    checkPhone();
    return callsDispatchWithProgress<any>(
      wrapApiError(
        connection.rest.post(`user/${user.id}/sendDtmf`, {
          callId,
          digits,
        })
      )
    );
  };
};
