interface SipConnectionConfig {
  id: string;
  baseDomain: string;
}

export const SipConnectionService = {
  getWebSocketUrl(baseDomain: string): string {
    return `wss://wss.${baseDomain}:4433`;
  },

  getSipAddress(config: SipConnectionConfig): string {
    return `sip:phone_${config.id}_0@sip.${config.baseDomain}`;
  },
};
